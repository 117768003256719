import styled from "@emotion/styled";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import Styles from "../views/public/Style";

const ButtonComprar = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "15px 35px",
    borderRadius: 0,
    lineHeight: 1.5,
    color: "white",
    backgroundColor: "#b19975",
    borderColor: "#b19975",
    fontFamily: "Open Sans",
    "&:hover": {
        backgroundColor: "#424242",
        color: "white",
        borderColor: "#424242",
        boxShadow: "10 10 10 0.2rem #424242",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#424242",
        borderColor: "#424242",
    },
});

const XecSelect = ({ item, setXec, regal }) => {
    const { t, i18n } = useTranslation();
    const classes = Styles();
    return (
        <Box className={classes.blockXec}>
            <Box>
                <Typography variant="title">
                    {i18n.language === "en"
                        ? item?.nom_en
                        : i18n.language === "es"
                        ? item?.nom_es
                        : item?.nom}
                </Typography>
                <Box my={4} />
                <ul className={classes.list}>
                    {i18n.language === "en"
                        ? item?.descripcio_en?.split("\n")?.map((de) => (
                              <li>
                                  <Typography>{de}</Typography>
                              </li>
                          ))
                        : i18n.language === "es"
                        ? item?.descripcio_es?.split("\n")?.map((de) => (
                              <li>
                                  <Typography>{de}</Typography>
                              </li>
                          ))
                        : item?.descripcio?.split("\n")?.map((de) => (
                              <li>
                                  <Typography>{de}</Typography>
                              </li>
                          ))}
                </ul>
                <Box my={4} />
            </Box>
            {!regal ? (
                <Box>
                    <Typography variant="body1">{t("Preu")}:</Typography>
                    <Typography variant="h4" style={{ fontWeight: 700 }}>
                        {item?.preu} €
                    </Typography>
                    <Typography variant="caption">{t("IVA inclòs")}</Typography>
                    <Box my={2} />
                    {setXec && (
                        <Box>
                            <ButtonComprar
                                variant="contained"
                                onClick={() => setXec(item)}
                            >
                                {t("Comprar")}
                            </ButtonComprar>
                        </Box>
                    )}
                </Box>
            ) : (
                <Box>
                    <Typography variant="body1">{t("Per a")}:</Typography>
                    <Typography className={classes.persona}>
                        {regal.nom_regal}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default XecSelect;
