import FullCalendar from "@fullcalendar/react";
import {
    Box,
    Button,
    Container,
    Fade,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import ReservaFutura from "../../components.js/ReservaFutura";
import Title from "../../components.js/Title";
import { getAdmin } from "../../database/API";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
import { useNavigate } from "react-router";
import { Add } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
        paddingBottom: 80,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 80,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },
    calendar: {
        fontFamily: "Open Sans",
        color: theme.palette.text.primary,
        height: 550,
        marginBottom: 100,
        "& .fc-button-primary": {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            "&:hover": {
                backgroundColor: theme.palette.primary.hover,
                borderColor: theme.palette.primary.hover,
            },
        },
        "& .fc-scroller-harness-liquid": {
            height: 500,
        },
    },
}));

const HomeView = () => {
    const classes = useStyles();
    const [reserves, setReserves] = useState([]);
    const [events, setEvents] = useState([]);
    const [update, setUpdate] = useState(0);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { resultat } = await getAdmin("reservesActuals");
            let events = [];
            resultat.map((item) =>
                events.push({
                    title: "nº " + item.id,
                    start: item.dateS,
                    end: moment(item.dateF)
                        .add("1", "day")
                        .format("YYYY-MM-DD"),
                    color: item.habitacio.color,
                })
            );
            setEvents(events);
            setReserves(resultat);
            setLoading(false);
        };

        get();
    }, [update]);

    return (
        <Container maxWidth={false} className={classes.container}>
            <Box>
                <Box pt={2} spacing={3}>
                    <Title
                        title="Inici"
                        button={
                            <Box>
                                <Button
                                    onClick={() => navigate("/admin/add")}
                                    variant="contained"
                                >
                                    <Add />{" "}
                                    <Typography>Afegir reserva</Typography>
                                </Button>
                            </Box>
                        }
                    />
                    <Box my={4} />
                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <Box>
                                <Typography variant="h3">
                                    Properes entrades
                                </Typography>
                                <Box my={3} />
                                <Fade in={!loading}>
                                    <div>
                                        {reserves?.map((item) => (
                                            <ReservaFutura
                                                reserva={item}
                                                setUpdate={setUpdate}
                                            />
                                        ))}
                                    </div>
                                </Fade>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Typography variant="h3">Calendari</Typography>
                            <Box my={3} />
                            <Box className={classes.block}>
                                <Fade in={!loading}>
                                    <div className={classes.calendar}>
                                        <FullCalendar
                                            plugins={[dayGridPlugin]}
                                            initialView="dayGridMonth"
                                            events={events}
                                            locale={"ca"}
                                            firstDay={1}
                                            displayEventTime={false}
                                            buttonText={{ today: "avui" }}
                                        />
                                    </div>
                                </Fade>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default HomeView;
