import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";

function Desglos({ preus, preu }) {
    const classes = useStyles();

    return (
        <Box className={classes.block}>
            <Typography variant="h2">Desglossament factura</Typography>
            <Box m={3}></Box>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        {preus.map((item) => (
                            <TableRow>
                                <TableCell align="left">{item[0]}</TableCell>
                                <TableCell align="left">{item[1]}</TableCell>
                                <TableCell align="left">{item[2]}</TableCell>
                                <TableCell
                                    align="right"
                                    style={{
                                        fontWeight: 600,
                                    }}
                                >
                                    {item[3]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default Desglos;

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
    },
    dates: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));
