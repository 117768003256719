import {
    Box,
    CircularProgress,
    Container,
    Fade,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../../theme/style.css";
import { calendaris, codiCorrecte, get } from "../../database/API";
import "moment/locale/ca";
import Styles from "./Style";
import { animated, useSpring } from "@react-spring/web";
import CustomButton from "../../components.js/CustomButton";
import { useSnackbar } from "notistack";
import ComGastar from "../../components.js/ComGastar";
import ReactDatePicker from "react-datepicker";
import FormulariReserva from "../../components.js/FormulariReserva";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ca } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { WheelchairPickupRounded } from "@mui/icons-material";
import Dates from "../../components.js/Dates";
import { addDays } from "date-fns";
import moment from "moment";
import clsx from "clsx";
import Condicions from "../../components.js/Condicions";
import XecSelect from "../../components.js/XecSelect";
import { useParams } from "react-router";
import { scroller, Element } from "react-scroll";
import { useTranslation } from "react-i18next";

const vacances = "cbbdq5e8mk85n9a5vi0d706lag@group.calendar.google.com";

const GastaXec = () => {
    const classes = Styles();
    const [loading, setLoading] = useState(false);
    const { key } = useParams();
    const [codi, setCodi] = useState("");
    const [codiG, setCodiG] = useState();
    const [showContinuarCodi, setShowContinuarCodi] = useState(false);
    const [showContinuar, setShowContinuar] = useState(false);
    const [dateS, setDateS] = useState();
    const [dateF, setDateF] = useState();
    const [persones, setPersones] = useState("");
    const [personesRender, setPersonesRender] = useState();
    const [exclude, setExclude] = useState([]);
    const [habitacio, setHabitacio] = useState();
    const [habitacions, setHabitacions] = useState();
    const [nomContacte, setNomContacte] = useState();
    const [emailContacte, setEmailContacte] = useState();
    const [phoneContacte, setPhoneContacte] = useState();
    const refInfo = useRef();
    const refXecs = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const refDates2 = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        if (key !== "") {
            setCodi(key);
        }
        const obtenir = async () => {
            const habitacions = await get("habitacions");

            setHabitacions(habitacions.resultat);
            setLoading(false);
        };
        obtenir();
    }, [key]);

    const comprovar = async () => {
        setLoading(true);
        const { message, success, codiG } = await codiCorrecte(codi);
        if (success === "success") {
            setCodiG(codiG);
            setShowContinuarCodi(true);
        }
        enqueueSnackbar(message, {
            variant: success,
        });
        setLoading(false);
    };

    const props = useSpring({
        from: {
            opacity: 0,
            opacityD: 1,
            height: refXecs?.current?.clientHeight,
            heightD: refInfo?.current?.clientHeight,
        },
        opacity: showContinuarCodi ? 0 : 1,
        height: showContinuarCodi ? 0 : refXecs?.current?.clientHeight,
        opacityD: showContinuarCodi ? 1 : 0,
        heightD: showContinuarCodi ? refInfo?.current?.clientHeight : 0,
    });

    const changeHabitacio = (value) => {
        setHabitacio(value);
        setDateS();
        setDateF();
    };
    const onChange = (dates) => {
        const [start, end] = dates;
        const datesOcupades = datesBetween(start, end);
        let trobat = [];
        datesOcupades.forEach((item) => {
            trobat = [
                ...trobat,
                ...exclude.filter((d) => d.getTime() === item.getTime()),
            ];
        });

        const boolDosNits =
            datesOcupades.length === codiG?.xec?.nits ||
            datesOcupades.length === 0;

        if (
            trobat.length === 0 &&
            start?.getTime() !== end?.getTime() &&
            boolDosNits
        ) {
            setDateS(start);
            setDateF(end);
        } else {
            setDateS();
            setDateF();
        }
    };

    useEffect(() => {
        setLoading(true);
        setPersones("");

        const getCalendars = async () => {
            const result = await calendaris(habitacio?.target.value.calendari);
            const result_vacances = await calendaris(vacances);
            let ex = [];
            result.forEach((item) => {
                ex = [...ex, ...datesBetween2(item.start.date, item.end.date)];
            });
            result_vacances.forEach((item) => {
                ex = [...ex, ...datesBetween2(item.start.date, item.end.date)];
            });
            setExclude(ex);
            var rows = [];
            for (var i = 0; i < 2; i++) {
                if (!(habitacio.target.value.discapacitats && i === 0)) {
                    rows.push(
                        <MenuItem value={i}>
                            {i + 1} {i === 0 ? "persona " : "persones"}
                        </MenuItem>
                    );
                }
            }
            setPersonesRender(rows);
            setTimeout(() => {
                setLoading(false);
            }, 300);
        };

        getCalendars();
    }, [habitacio]);

    var datesBetween = function (startDate, endDate) {
        var dates = [];

        var currDate = moment(startDate).startOf("day");
        var lastDate = moment(endDate).startOf("day");

        while (currDate.add(1, "days").diff(lastDate) <= 0) {
            dates.push(currDate.clone().toDate());
        }

        return dates;
    };

    var datesBetween2 = function (startDate, endDate) {
        var dates = [];

        var currDate = moment(startDate).startOf("day").subtract(1, "days");
        var lastDate = moment(endDate).startOf("day").subtract(1, "days");

        while (currDate.add(1, "days").diff(lastDate) <= 0) {
            dates.push(currDate.toDate());
        }

        return dates;
    };

    const continuar = () => {
        scroller.scrollTo("formulari", {
            duration: 400,
            delay: 400,
            smooth: true,
            offset: -120,
        });
        setShowContinuar(!showContinuar);
    };

    return (
        <Box className={classes.main}>
            <Fade in={loading}>
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            </Fade>

            <ComGastar />

            <Container>
                <LocalizationProvider
                    locale={ca}
                    utils={DateFnsUtils}
                    dateAdapter={DateFnsUtils}
                >
                    <animated.div
                        className={classes.first}
                        style={{
                            height: props.height,
                            opacity: props.opacity,
                        }}
                    >
                        <Container maxWidth="sm">
                            <Box className={classes.reserva}>
                                <Box
                                    justifyContent="center"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <TextField
                                        onChange={(value) =>
                                            setCodi(value.target.value)
                                        }
                                        name="codi"
                                        type="text"
                                        variant="outlined"
                                        label={t("Entra el codi")}
                                        value={codi}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <Box my={2} />
                                    <CustomButton
                                        onClick={comprovar}
                                        title={t("Comprovar")}
                                    />
                                </Box>
                            </Box>
                        </Container>
                    </animated.div>
                    <animated.div
                        style={{
                            opacity: props.opacityD,
                        }}
                    >
                        <Typography variant="h2">
                            {t("El Xec Regal inclou")}:
                        </Typography>
                        <XecSelect item={codiG?.xec} regal={codiG} />
                        <animated.div
                            ref={refDates2}
                            className={clsx(classes.reserva)}
                        >
                            <Box mb={3}>
                                <Typography variant="h3">
                                    {t("Fes la reserva")}
                                </Typography>
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item md={8}>
                                    <Box className={classes.form}>
                                        <Box>
                                            <Box mb={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        {t("Habitació")}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={habitacio}
                                                        label={t("Habitació")}
                                                        onChange={(value) =>
                                                            changeHabitacio(
                                                                value
                                                            )
                                                        }
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                        }}
                                                    >
                                                        {habitacions?.map(
                                                            (hab) => (
                                                                <MenuItem
                                                                    value={hab}
                                                                >
                                                                    {hab.nom}
                                                                    {hab.discapacitats ? (
                                                                        <WheelchairPickupRounded
                                                                            style={{
                                                                                marginLeft: 10,
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Box>

                                            <Box mb={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        {t("Persones")}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={persones}
                                                        label="Persones"
                                                        onChange={(value) => {
                                                            setPersones(
                                                                value.target
                                                                    .value
                                                            );
                                                        }}
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                        }}
                                                    >
                                                        {personesRender}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box mb={3} mt={3}>
                                                <Grid spacing={3} container>
                                                    <Grid item md={4} xs={12}>
                                                        <TextField
                                                            name="nom_contacte"
                                                            label={t(
                                                                "Nom i cognoms"
                                                            )}
                                                            type="text"
                                                            value={nomContacte}
                                                            onChange={(value) =>
                                                                setNomContacte(
                                                                    value.target
                                                                        .value
                                                                )
                                                            }
                                                            fullWidth
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item md={4} xs={12}>
                                                        <TextField
                                                            name="nom_contacte"
                                                            label="E-mail"
                                                            type="text"
                                                            value={
                                                                emailContacte
                                                            }
                                                            onChange={(value) =>
                                                                setEmailContacte(
                                                                    value.target
                                                                        .value
                                                                )
                                                            }
                                                            fullWidth
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item md={4} xs={12}>
                                                        <TextField
                                                            name="phone_contacte"
                                                            label={t("Telèfon")}
                                                            type="text"
                                                            value={
                                                                phoneContacte
                                                            }
                                                            onChange={(value) =>
                                                                setPhoneContacte(
                                                                    value.target
                                                                        .value
                                                                )
                                                            }
                                                            fullWidth
                                                            required
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Dates
                                                dateF={dateF}
                                                dateS={dateS}
                                            />
                                            <Typography variant="caption">
                                                {t(
                                                    "Selecciona la data al calendari situat a la dreta"
                                                )}
                                            </Typography>
                                            <br />
                                            <Typography variant="caption">
                                                {codiG?.xec?.nits}{" "}
                                                {t("nit/nits")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={4}>
                                    <ReactDatePicker
                                        onChange={onChange}
                                        startDate={dateS}
                                        endDate={dateF}
                                        excludeDates={exclude}
                                        selectsRange
                                        selectsDisabledDaysInRange
                                        locale={ca}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={addDays(new Date(), 1)}
                                        inline
                                    />
                                </Grid>
                            </Grid>
                            <Box my={4} />
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                            >
                                <Box mr={2}>
                                    <Typography variant="h3">
                                        {t("Preu")}:
                                    </Typography>
                                </Box>

                                <Typography variant="h2">
                                    {t("REGAL")}
                                </Typography>
                            </Box>
                        </animated.div>
                        <Box className={classes.continuar}>
                            <CustomButton
                                onClick={continuar}
                                title={t("Continuar")}
                                disabled={
                                    !dateS ||
                                    !dateF ||
                                    !habitacio ||
                                    persones === ""
                                }
                            />
                        </Box>
                    </animated.div>
                </LocalizationProvider>
                <Element name="formulari">
                    <FormulariReserva
                        showContinuar={showContinuar}
                        habitacio={habitacio?.target?.value}
                        dateS={dateS}
                        dateF={dateF}
                        persones={persones}
                        preu={0}
                        loading={loading}
                        setLoading={setLoading}
                        xecView={true}
                        codi={codiG?.codi}
                        nomContacte={nomContacte}
                        emailContacte={emailContacte}
                        phoneContacte={phoneContacte}
                    />
                </Element>

                <Condicions showNits={false} />
            </Container>
        </Box>
    );
};

export default GastaXec;
