import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    Box,
} from "@mui/material";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { createHabitacio, updateHabitacio } from "../database/API";
import { useSnackbar } from "notistack";

const HabitacioEdit = ({ item, open, setOpen, setUpdate }) => {
    const { enqueueSnackbar } = useSnackbar();

    const guardar = async (values) => {
        let message = "";
        if (item) {
            message = await updateHabitacio(values, item.id);
        } else {
            message = await createHabitacio(values);
        }
        enqueueSnackbar(message, {
            variant: "success",
        });
        setUpdate((prev) => prev + 1);
        setOpen(false);
    };
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
        >
            <DialogTitle id="alert-dialog-title">
                {item ? "Editar" : "Crear"} {item?.nom}
            </DialogTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    nom: item?.nom,
                    nom_es: item?.nom_es,
                    nom_en: item?.nom_en,
                    persones: item?.persones,
                    min_persones: item?.min_persones,
                    discapacitats: item?.discapacitats,
                    calendari: item?.calendari,
                }}
                validationSchema={Yup.object().shape({
                    nom: Yup.string().max(255).required("El nom és obligatòri"),
                    persones: Yup.number().required(
                        "Les persones son obligatòries"
                    ),
                    calendari: Yup.string().required(
                        "El calendari és obligatòri"
                    ),
                })}
                onSubmit={(values, actions) => {
                    guardar(values, actions);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    resetForm,
                    isSubmitting,
                    setFieldValue,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item md={12}>
                                    <Box
                                        p={3}
                                        style={{
                                            border: "1px solid #cacaca",
                                            borderRadius: 10,
                                        }}
                                    >
                                        <TextField
                                            error={Boolean(
                                                touched.nom && errors.nom
                                            )}
                                            fullWidth
                                            helperText={
                                                touched.nom && errors.nom
                                            }
                                            label={"Nom"}
                                            margin="normal"
                                            name="nom"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.nom}
                                            variant="outlined"
                                        />
                                        <TextField
                                            error={Boolean(
                                                touched.nom_es && errors.nom_es
                                            )}
                                            fullWidth
                                            helperText={
                                                touched.nom_es && errors.nom_es
                                            }
                                            label={"Nombre castellano"}
                                            margin="normal"
                                            name="nom_es"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.nom_es}
                                            variant="outlined"
                                        />
                                        <TextField
                                            error={Boolean(
                                                touched.nom_en && errors.nom_en
                                            )}
                                            fullWidth
                                            helperText={
                                                touched.nom_en && errors.nom_en
                                            }
                                            label={"Nom anglès"}
                                            margin="normal"
                                            name="nom_en"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.nom_en}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        error={Boolean(
                                            touched.min_persones &&
                                                errors.min_persones
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.min_persones &&
                                            errors.min_persones
                                        }
                                        label={"Persones mínimes"}
                                        margin="normal"
                                        name="min_persones"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.min_persones}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        error={Boolean(
                                            touched.persones && errors.persones
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.persones && errors.persones
                                        }
                                        label={"Persones màximes"}
                                        margin="normal"
                                        name="persones"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.persones}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item md={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.calendari &&
                                                errors.calendari
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.calendari &&
                                            errors.calendari
                                        }
                                        label={"ID Calendari"}
                                        margin="normal"
                                        name="calendari"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.calendari}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.discapacitats}
                                        onChange={(e) => {
                                            setFieldValue(
                                                "discapacitats",
                                                e?.target.checked
                                            );
                                        }}
                                        name="discapacitats"
                                    />
                                }
                                label={
                                    <Typography>
                                        Adaptada per a discapacitats
                                    </Typography>
                                }
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>
                                Tancar
                            </Button>
                            <Button type="submit" autoFocus>
                                Guardar
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default HabitacioEdit;
