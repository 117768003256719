import i18n from "../../lang";
import { useState } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import { Box, InputBase, MenuItem, Select } from "@mui/material";
import { Globe } from "react-feather";

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    color: "#9f9f9f !important",
    border: 1,
    fontSize: 13,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Open Sans !important",
    "&:focus": {
      borderRadius: 4,
      color: "#9f9f9f",
      borderColor: "transparent",
      boxShadow: "0",
      backgroundColor: "transparent",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  menuItem: {
    fontSize: "15px !important",
    fontWeight: 400,
    color: "#9f9f9f",
    marginRight: 10,
  },
}));

const LangSelector = () => {
  const classes = useStyles();
  const [lang, setLang] = useState(i18n.language);

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang);
  };

  return (
    <Box ml={3}>
      <Globe color="#9f9f9f" size={13} style={{ marginRight: 8 }} />
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={lang}
        input={<BootstrapInput />}
        inputProps={{
          classes: {
            icon: classes.arrow,
            text: classes.menuItem,
          },
        }}
        MenuProps={{ disableScrollLock: true }}
      >
        <MenuItem
          className={classes.menuItem}
          value={"ca"}
          onClick={() => changeLang("ca")}
        >
          Ca
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          value={"es"}
          onClick={() => changeLang("es")}
        >
          Es
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          value={"en"}
          onClick={() => changeLang("en")}
        >
          En
        </MenuItem>
      </Select>
    </Box>
  );
};

export default LangSelector;
