import { Box, Container, Grid, Hidden, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../assets/img/logo_gran.svg";
import Styles from "../views/public/Style";

export default function ReservaIntro() {
    const { t } = useTranslation();
    const classes = Styles();
    return (
        <Box className={classes.root}>
            <Container>
                <Grid container>
                    <Grid item md={6} sx={12}>
                        <Box mt={6}>
                            <Typography variant="h2">
                                {t("Reserva d'habitacions")}
                            </Typography>
                            <Typography variant="h4" className={classes.text}>
                                {t(
                                    "La Cabana hotel ha estat pensat per a poder fer reserves amb la màxima comoditat i discreció. Durant tot el procés no hi ha ni interaccions ni esperes."
                                )}
                            </Typography>
                            <Typography variant="h4" className={classes.text}>
                                {t(
                                    "Escull una habitació, el nombre de persones i els dies que vols fer l'estada. Un cop finalitzat el procés t'enviarem un codi que et permetrà accedir a les instal·lacions de La Cabana i a la teva habitació."
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                    <Hidden mdDown>
                        <Grid
                            item
                            md={6}
                            sx={12}
                            style={{
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            <img src={logo} alt="logo" width={300} />
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </Box>
    );
}
