import moment from "moment";

var datesBetween = function (startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
        dates.push(currDate.clone().toDate());
    }

    return dates;
};

var datesBetween2 = function (startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day").subtract(1, "days");
    var lastDate = moment(endDate).startOf("day").subtract(2, "days");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
        dates.push(currDate.toDate());
    }

    return dates;
};

var datesBetweenInclosos = function (startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day").subtract(1, "days");
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
        dates.push(currDate.toDate());
    }

    return dates;
};

function mergeIntervals(intervals) {
    if (!Array.isArray(intervals) || intervals.length < 2) {
        return intervals;
    }

    // Ordenamos los intervalos por la fecha inicial.
    intervals.sort((a, b) => moment(a[0]) - moment(b[0]));

    const mergedIntervals = [];
    let currentInterval = intervals[0];

    for (let i = 1; i < intervals.length; i++) {
        const interval = intervals[i];

        if (moment(interval[0]) <= moment(currentInterval[1])) {
            // Si hay intersección, combinamos los intervalos.
            currentInterval[1] = moment
                .max([moment(currentInterval[1]), moment(interval[1])])
                .format("YYYY-MM-DD");
        } else {
            // Si no hay intersección, añadimos el intervalo actual a la matriz de intervalos combinados.
            mergedIntervals.push(currentInterval);
            currentInterval = interval;
        }
    }

    // Añadimos el último intervalo actual a la matriz de intervalos combinados.
    mergedIntervals.push(currentInterval);

    return mergedIntervals;
}

export { datesBetween, datesBetween2, mergeIntervals, datesBetweenInclosos };
