import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';

const theme = createTheme({
    palette: {
        background: {
            dark: '#F7F6F4',
            default: '#F7F6F4',
        },
        primary: {
            main: '#b19975',
            hover: '#9f8764',
        },
        secondary: {
            main: '#E5E3DB',
            hover: '#0000003b',
        },
        danger: {
            main: colors.red[500],
            light: colors.red[300],
        },
        success: {
            main: colors.green[500],
        },
        text: {
            primary: '#424242',
            secondary: '#b19975',
            danger: '#b19975',
        },
        typography: {
            fontFamily: 'Ubuntu',
        },
    },
    typography: {
        h1: {
            fontSize: '2rem',
            fontFamily: 'Montserrat',
            textTransform: 'uppercase',
            color: '#424242',
        },
        h2: {
            fontSize: '1.5rem',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            color: '#b19975',
        },
        h3: {
            fontSize: '1.2rem',
            fontFamily: 'Montserrat',
            color: '#424242',
        },
        h4: {
            fontSize: '1rem',
            fontFamily: 'Open Sans',
            color: '#424242',
        },
        h5: {
            fontFamily: 'Open Sans',
            fontWeight: 800,
            color: '#424242',
        },
        body1: {
            fontFamily: 'Open Sans',
            color: '#424242',
        },
        body2: {
            fontFamily: 'Open Sans',
            fontSize: '0.8rem',
            color: '#9f9f9f',
        },
        caption: {
            fontFamily: 'Open Sans',
            color: '#9f9f9f',
        },
        root: {
            '& a': {
                color: '#5e5e5e',
                textDecoration: 'none',
                transition: '0.5s',
                '&:hover': {
                    color: 'black',
                },
            },
        },
        error: {
            fontFamily: 'Open Sans',
            color: '#d32f2f',
            fontSize: '0.75rem',
        },
        title: {
            fontFamily: 'Cookie ',
            fontSize: '3.5rem',
            color: '#b19975',
        },
    },
    '& a': {
        color: 'black',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1150,
            xl: 1920,
        },
    },
});

export default theme;
