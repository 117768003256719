import { IconButton } from "@mui/material";
import moment from "moment";
import { Eye } from "react-feather";
import { useNavigate } from "react-router";
import ReservaState from "../../../../components.js/ReservaState";
import { CheckCircle, Delete, Remove } from "@mui/icons-material";

const Columns = (orders) => {
    const navigate = useNavigate();
    const columns = [
        {
            name: "unique_id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "id",
            label: "Nº",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "state.nom",
            label: "Estat",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return <ReservaState state={value} />;
                },
            },
        },

        {
            name: "nom_contacte",
            label: "Nom",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "email_contacte",
            label: "E-mail",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "persones",
            label: "Nom client",
            options: {
                filter: false,
                display: false,
                sort: true,
                customBodyRender: (value) =>
                    value[0]?.nom +
                    " " +
                    value[0]?.cognom1 +
                    " " +
                    value[0]?.cognom2,
            },
        },

        {
            name: "dateS",
            label: "Data entrada",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
                customBodyRender: (value) =>
                    moment(new Date(value)).format("DD/MM/YYYY"),
            },
        },

        {
            name: "dateF",
            label: "Data sortida",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
                customBodyRender: (value) =>
                    moment(new Date(value)).format("DD/MM/YYYY"),
            },
        },
        {
            name: "habitacio.nom",
            label: "Habitació",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "preu",
            label: "Preu",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => value + "€",
            },
        },
        {
            name: "codi",
            label: "Codi",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "booking",
            label: "Booking",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return value === "1" ? <CheckCircle /> : <Remove />;
                },
            },
        },
        {
            name: "persones",
            label: "Telèfon",
            options: {
                filter: false,
                sort: true,
                display: false,
                customBodyRender: (value) => value[0]?.phone,
            },
        },
        {
            name: "persones",
            label: "E-mail client",
            options: {
                filter: false,
                sort: true,
                display: false,
                customBodyRender: (value) => value[0]?.email,
            },
        },

        {
            name: "created_at",
            label: "Data creació",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
                customBodyRender: (value) =>
                    moment(new Date(value)).format("DD/MM/YYYY HH:mm"),
            },
        },
        {
            name: "unique_id",
            label: "Accions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() =>
                                navigate(
                                    `/admin/${orders[dataIndex].unique_id}`
                                )
                            }
                        >
                            <Eye />
                        </IconButton>
                    );
                },
            },
        },
    ];
    return columns;
};

export default Columns;
