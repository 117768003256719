import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { Box, Container, Fade, Grid, Typography } from "@mui/material";
import Title from "../../../components.js/Title";
import ReservaState from "../../../components.js/ReservaState";
import { getReserva, get, downloadFactura, obtenirPreus } from "../../../database/API";
import Dates from "./Components/Dates";
import Clients from "./Components/Clients";
import Factura from "./Components/Factura";
import Informacio from "./Components/Informacio";
import CanviEstat from "./Components/CanviEstat";
import Devolucio from "./Components/Devolucio";
import Desglos from "./Components/Desglos";
import Loading from "../../../components.js/Loading";
import Notes from "./Components/Notes";

const ReservaView = () => {
    const classes = useStyles();
    const { key } = useParams();
    const [states, setStates] = useState([]);
    const [up, setUp] = useState(0);
    const [reserva, setReserva] = useState();
    const [preus, setPreus] = useState([]);
    const [loading, setLoading] = useState(true);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [preuFinal, setPreuFinal] = useState(reserva?.preu);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const obtenir = async () => {
            const { reserva } = await getReserva(key);
            const { preus, preu_final } = await obtenirPreus(key);
            const { resultat } = await get("states");
            setPreuFinal(preu_final);
            setPreus(preus);
            setStates(resultat);
            setReserva(reserva);
            setLoading(false);
        };
        obtenir();
    }, [key, up]);

    const downloadFact = async () => {
        setDownloadLoading(true);
        const { message } = await downloadFactura(key);
        setDownloadLoading(false);
    };

    return (
        <Container maxWidth={false} className={classes.container}>
            <Loading loading={loading}>
                <Grid container spacing={3}>
                    <Fade in={!loading}>
                        <Grid item md={12}>
                            <Box mb={3}>
                                <Title
                                    title={"Resum reserva nº " + reserva?.id}
                                    button={
                                        <Box display="flex" alignItems="center">
                                            <Typography style={{ marginRight: 20 }}>{moment(reserva?.created_at).format("LLL")}</Typography>

                                            <ReservaState state={reserva?.state.nom} />
                                        </Box>
                                    }
                                />
                                <Typography variant="caption">ID: {reserva?.unique_id}</Typography>
                            </Box>

                            <Grid container spacing={3}>
                                <Grid item md={8}>
                                    <Dates reserva={reserva} />
                                    <Clients reserva={reserva} setUp={setUp} />

                                    {reserva?.rao && (
                                        <Factura
                                            reserva={reserva}
                                            downloadFactura={downloadFact}
                                            loading={downloadLoading}
                                            setLoading={setLoading}
                                            setUp={setUp}
                                        />
                                    )}
                                    <Notes reserva={reserva} setUp={setUp} setLoading={setLoading} />
                                </Grid>
                                <Grid item md={4}>
                                    <Informacio
                                        reserva={reserva}
                                        preuFinal={preuFinal}
                                        setUp={setUp}
                                        downloadFactura={downloadFact}
                                        loading={downloadLoading}
                                        setLoading={setLoading}
                                        setDownloadLoading={setDownloadLoading}
                                    />
                                    <CanviEstat reserva={reserva} setUp={setUp} states={states} />
                                    {reserva?.rao && <Desglos preus={preus} preu={reserva?.preu} />}
                                    {!reserva?.codi_xec && <Devolucio reserva={reserva} setLoading={setLoading} />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fade>
                </Grid>
            </Loading>
        </Container>
    );
};

export default ReservaView;

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 40,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
}));
