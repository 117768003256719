import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
    afegirPersonaAPI,
    comprovarPersonaAPI,
} from "../../../../database/API";
import { useSnackbar } from "notistack";

export default function ComprovarPersona({ open, setOpen, reserva_id, setUp }) {
    const [persona, setPersona] = useState("");
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const obtenir = async () => {
            setLoading(true);
            const { data } = await comprovarPersonaAPI();
            setPersona(data);
            setLoading(false);
        };

        open && obtenir();
    }, [open]);

    const afegirPersona = async () => {
        const values = { reserva_id: reserva_id };
        try {
            const message = await afegirPersonaAPI(values, persona.id);
            enqueueSnackbar(message, {
                variant: "success",
            });
            setUp((prev) => prev + 1);
            setOpen(false);
        } catch (error) {
            enqueueSnackbar("Alguna cosa no ha anat bé...", {
                variant: "error",
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle>
                <Typography variant="h2">Afegir la següent persona?</Typography>
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Box>
                        <Typography>Nom: {persona.nom}</Typography>
                        <Typography>
                            Cognoms: {persona.cognom1} {persona.cognom2}
                        </Typography>
                        <Typography>Número document: {persona.dni}</Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpen(false)}
                    variant="contained"
                    color="error"
                >
                    TANCAR
                </Button>
                <Button
                    onClick={afegirPersona}
                    variant="contained"
                    color="success"
                >
                    Afegir persona
                </Button>
            </DialogActions>
        </Dialog>
    );
}
