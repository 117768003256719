import { Box, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '../views/public/Style';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 150,
        paddingBottom: 200,
        backgroundSize: 'cover',
        overflow: 'hidden',
        justifyContent: 'center',
    },

    text: {
        paddingTop: 25,
        fontSize: '18px !important',
    },
}));

export default function ComGastar() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box className={classes.root}>
            <Container>
                <Grid container>
                    <Grid item md={6} sx={12}>
                        <Box mt={6}>
                            <Typography variant="h2">
                                {t('Com validar un Xec Regal?')}
                            </Typography>
                            <Typography variant="h4" className={classes.text}>
                                1. {t('Entra el codi del teu Xec Regal')}
                            </Typography>
                            <Typography variant="h4" className={classes.text}>
                                2.{' '}
                                {t(
                                    "Tria l'habitació i els dies que vols fer l'estada"
                                )}
                            </Typography>
                            <Typography variant="h4" className={classes.text}>
                                3. {t('Fes la reserva')}
                            </Typography>
                            <Typography variant="h4" className={classes.text}>
                                4.{' '}
                                {t(
                                    'Un cop feta la reserva, rebràs un e-mail de confirmació amb un CODI per les portes que et permetrà entrar tant al recinte com a la teva habitació de La Cabana Hotel'
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
