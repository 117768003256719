import React from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import logo from '../../assets/img/logo_petit.svg';
import { ArrowLeft } from 'react-feather';
import LangSelector from '../../lang/translations/LangSelector';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: 'white',
        width: '100%',
        zIndex: 100,
        paddingTop: 20,
        paddingBottom: 20,
        borderBottom: '1px solid ' + theme.palette.secondary.hover,
        position: 'fixed',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    enrere: {
        display: 'flex',
        textDecoration: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 2,
    },
    text: {
        fontSize: '15px !important',
        fontWeight: 400,
        color: '#9f9f9f',
        marginRight: 10,
    },
}));

const TopBarPublic = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box className={classes.main}>
            <Container>
                <Box className={classes.header}>
                    <a href="https://lacabanaberga.cat">
                        <img src={logo} alt="logo" width={200} />
                    </a>
                    <Box display="flex">
                        <a
                            className={classes.enrere}
                            href="https://lacabanaberga.cat/hotel"
                        >
                            <ArrowLeft className={classes.text} />{' '}
                            <Typography
                                variant="caption"
                                className={classes.text}
                            >
                                {t('Enrere')}
                            </Typography>
                        </a>
                        <LangSelector />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

TopBarPublic.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

export default TopBarPublic;
