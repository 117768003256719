import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import FacturesTable from "./FacturesTable";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
}));

const FacturesAdmin = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container maxWidth={false} className={classes.container}>
            <Box>
                <Box pt={2} spacing={3}>
                    <FacturesTable />
                </Box>
            </Box>
        </Container>
    );
};

export default FacturesAdmin;
