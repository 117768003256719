import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Styles from '../views/public/Style';
import parse from 'html-react-parser';
import CustomButton from './CustomButton';
import { createCodi } from '../database/API';
import { useTranslation } from 'react-i18next';
import { SchemaRes } from './SchemaReserva';

const FormCompraXec = ({ xec_id, setLoading }) => {
    const classes = Styles();
    const [form, setForm] = useState();
    const { t, i18n } = useTranslation();
    const { schemaXec } = SchemaRes();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schemaXec),
    });

    useEffect(() => {
        setValue('xec_id', xec_id);
        setValue('lang', i18n.language);
    }, [xec_id, i18n.language]);

    const enviar = async (values) => {
        setLoading(true);
        const { form } = await createCodi(values);
        setForm(form);

        setTimeout(() => document.forms['redsys_form'].submit(), 500);
    };

    return (
        <form onSubmit={handleSubmit(enviar)}>
            <Box className={classes.blockXec}>
                {parse(form ? form.data : '')}
                <Box>
                    <Box mb={2}>
                        <Typography variant="h2">
                            {t('Omple les dades')}
                        </Typography>
                    </Box>
                    <input {...register('xec_id')} type="text" hidden />
                    <input {...register('lang')} type="text" hidden />

                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                error={Boolean(errors && errors?.nom)}
                                helperText={errors && errors?.nom?.message}
                                fullWidth
                                label={t('Nom i cognoms del comprador')}
                                name={'nom'}
                                type="text"
                                variant="outlined"
                                {...register('nom')}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField
                                error={Boolean(errors && errors?.nom_regal)}
                                helperText={
                                    errors && errors?.nom_regal?.message
                                }
                                fullWidth
                                label={t(
                                    'Nom/s de la persona o persones a qui va dirigit'
                                )}
                                name={'nom_regal'}
                                type="text"
                                variant="outlined"
                                {...register('nom_regal')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                error={Boolean(errors && errors?.email)}
                                helperText={errors && errors?.email?.message}
                                fullWidth
                                label={'E-mail'}
                                name={'email'}
                                type="text"
                                variant="outlined"
                                {...register('email')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                error={Boolean(errors && errors?.phone)}
                                helperText={errors && errors?.phone?.message}
                                fullWidth
                                label={t('Telèfon')}
                                name={'phone'}
                                type="text"
                                variant="outlined"
                                {...register('phone')}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box my={3} />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e) => {}}
                            name="accepta"
                            {...register('accepta')}
                            required
                        />
                    }
                    label={
                        <Typography>
                            {t('He llegit, entenc i accepto la')}{' '}
                            <a
                                href="https://lacabanaberga.cat/politica-de-privacitat"
                                target="_blank"
                                alt="Política de Privacitat"
                                rel="noreferrer"
                            >
                                {'Política de Privacitat'}
                            </a>
                        </Typography>
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e) => {}}
                            name="news"
                            {...register('news')}
                        />
                    }
                    label={
                        <Typography>
                            {t(
                                "Dono el meu consentiment exprés per rebre comunicacions dels serveis sol·licitats de LA CABANA BERGA, S.L. per correu electrònic, WhatsApp i altres mitjans de comunicació digital, segons l'establert a la Llei LSSI/CE 34/2002."
                            )}
                        </Typography>
                    }
                />
            </Box>
            <Box className={classes.continuar}>
                <CustomButton type="submit" title={'Comprar'} />
            </Box>
        </form>
    );
};

export default FormCompraXec;
