import { IconButton } from "@mui/material";
import { Eye, XCircle, CheckCircle } from "react-feather";
import { useNavigate } from "react-router";

const Columns = (orders, setPersona, setOpenEdit) => {
    const navigate = useNavigate();
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "nom",
            label: "Nom",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "cognom1",
            label: "Cognom",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "cognom2",
            label: "Segon cognom",
            options: {
                filter: false,
                sort: true,
            },
        },

        {
            name: "dni",
            label: "DNI",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "nacionalitat",
            label: "País",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "email",
            label: "E-mail",
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        },
        {
            name: "reserva_id",
            label: "Reserva ID",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return value ? value : "No té reserva";
                },
            },
        },
        {
            name: "news",
            label: "Newsletter",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return value ? (
                        <CheckCircle color="green" />
                    ) : (
                        <XCircle color="red" />
                    );
                },
            },
        },
        {
            name: "id",
            label: "Accions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() => {
                                // navigate(`/admin/${orders[dataIndex].id}`)
                                setPersona(orders[dataIndex]);
                                setOpenEdit(true);
                            }}
                        >
                            <Eye />
                        </IconButton>
                    );
                },
            },
        },
    ];
    return columns;
};

export default Columns;
