import {
    Box,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { useTranslation } from "react-i18next";

const FormFactura = ({ factura, setFactura }) => {
    const classes = useStyles();
    const refForm = useRef();
    const [factSel, setFactSel] = useState(false);
    const [heightForm, setHeightForm] = useState(
        refForm?.current?.clientHeight
    );
    const { t, i18n } = useTranslation();
    const props = useSpring({
        from: {
            opacity: 0,
            height: 0,
        },
        opacity: factSel ? 1 : 0,
        height: factSel ? heightForm : 0,
    });

    useEffect(() => {
        const subscription = () => {
            setTimeout(() => {
                setHeightForm(refForm?.current?.clientHeight);
            }, 200);
        };
        subscription();
    }, []);

    return (
        <Box spacing={3}>
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={(e) => setFactSel((prev) => !prev)}
                        name="Factura"
                    />
                }
                label={t("Necessita factura?")}
            />
            <animated.div
                className={classes.reserva}
                style={{
                    opacity: props.opacity,
                    height: props.height,
                }}
            >
                <Box className={classes.block} ref={refForm}>
                    <Box mb={2}>
                        <Typography variant="h2">
                            {t("Dades de facturació")}
                        </Typography>
                    </Box>
                    <Box ref={refForm}>
                        <Grid spacing={2} container>
                            <Grid item md={4} sm={12}>
                                <Box mb={2}>
                                    <TextField
                                        name="rao"
                                        value={factura.rao}
                                        type="text"
                                        onChange={(value) =>
                                            setFactura({
                                                ...factura,
                                                rao: value.target.value,
                                            })
                                        }
                                        fullWidth
                                        label={t("Raó social")}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={4} sm={12}>
                                <Box mb={2}>
                                    <TextField
                                        name="nif"
                                        value={factura.nif}
                                        type="text"
                                        onChange={(value) =>
                                            setFactura({
                                                ...factura,
                                                nif: value.target.value,
                                            })
                                        }
                                        fullWidth
                                        label="NIF"
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={4} sm={12}>
                                <Box mb={2}>
                                    <TextField
                                        name="pais"
                                        value={factura.pais}
                                        type="text"
                                        onChange={(value) =>
                                            setFactura({
                                                ...factura,
                                                pais: value.target.value,
                                            })
                                        }
                                        fullWidth
                                        label={t("País")}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid spacing={2} container>
                            <Grid item md={4} sm={12}>
                                <Box mb={2}>
                                    <TextField
                                        name="poblacio"
                                        value={factura.poblacio}
                                        type="text"
                                        onChange={(value) =>
                                            setFactura({
                                                ...factura,
                                                poblacio: value.target.value,
                                            })
                                        }
                                        fullWidth
                                        label={t("Població")}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={4} sm={12}>
                                <Box mb={2}>
                                    <TextField
                                        name="codi_postal"
                                        value={factura.codi_postal}
                                        type="text"
                                        onChange={(value) =>
                                            setFactura({
                                                ...factura,
                                                codi_postal: value.target.value,
                                            })
                                        }
                                        fullWidth
                                        label={t("Codi postal")}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={4} sm={12}>
                                <Box mb={2}>
                                    <TextField
                                        name="adreca"
                                        value={factura.adreca}
                                        type="text"
                                        onChange={(value) =>
                                            setFactura({
                                                ...factura,
                                                adreca: value.target.value,
                                            })
                                        }
                                        fullWidth
                                        label={t("Adreça")}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </animated.div>
        </Box>
    );
};

export default FormFactura;

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 80,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },
}));
