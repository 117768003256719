import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Box } from "@mui/system";
import moment from "moment";
import { useForm } from "react-hook-form";
import { addDia } from "../../../database/API";
import { useSnackbar } from "notistack";
import { dialogActionsClasses } from "@mui/material";

export const DialogAddDia = ({ open, setOpen, dia, preus, setDies, setLoading, dies }) => {
    const { enqueueSnackbar } = useSnackbar();

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
        setValue,
    } = useForm();

    useEffect(() => {
        if (open) {
            const diaSel = dies?.find((d) => moment(d.dia).format("YYYY-MM-DD") === moment(dia).format("YYYY-MM-DD"));

            setValue("data", moment(dia).format("YYYY-MM-DD"));
            setValue("data_fi", moment(dia).format("YYYY-MM-DD"));
            setValue("tantpercent_torre", diaSel?.tantpercent_torre);
            setValue("tantpercent_montserrat", diaSel?.tantpercent_montserrat);
            setValue("tantpercent_queralt", diaSel?.tantpercent_queralt);
            setValue("dosnits", diaSel?.dosnits ? true : false);
            console.log(dies?.find((d) => moment(d.dia).format("YYYY-MM-DD") === moment(dia).format("YYYY-MM-DD")));
        }
    }, [open]);

    const tancar = () => {
        setOpen(false);
        reset();
    };

    const enviar = async (values) => {
        setLoading(true);
        values.dates = getDateRange(values.data, values.data_fi);
        const { missatge, dies } = await addDia(values);
        setDies(dies);
        enqueueSnackbar(missatge, {
            variant: "success",
        });
        setOpen(false);
        setLoading(false);
    };

    return (
        <Dialog open={open} onClose={tancar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth={"lg"}>
            <DialogTitle>Modificar configuració {moment(dia).format("DD/MM/YYYY")}</DialogTitle>
            <DialogContent>
                <Box mt={2}>
                    <form onSubmit={handleSubmit(enviar)}>
                        <Box mb={3}>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <TextField
                                        error={Boolean(errors && errors?.data)}
                                        helperText={errors && errors?.data?.message}
                                        fullWidth
                                        label={"Data inici"}
                                        type="date"
                                        variant="outlined"
                                        {...register("data")}
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        error={Boolean(errors && errors?.data_fi)}
                                        helperText={errors && errors?.data_fi?.message}
                                        fullWidth
                                        label={"Data final"}
                                        type="date"
                                        variant="outlined"
                                        {...register("data_fi")}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mb={3}>
                            <TextField
                                error={Boolean(errors && errors?.tantpercent_torre)}
                                helperText={errors && errors?.tantpercent_torre?.message}
                                fullWidth
                                label={"La Torre (%)"}
                                type="number"
                                variant="outlined"
                                {...register("tantpercent_torre")}
                            />
                            <Box style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }} p={1} display={"flex"} justifyContent={"space-between"}>
                                <Typography variant="caption">Preu 1 persona: </Typography>
                                <Typography variant="caption">
                                    {Math.round(
                                        Number(preus.find((p) => p.habitacio_id === 1 && p.persones === 1)?.preu) *
                                            (watch("tantpercent_torre") ? watch("tantpercent_torre") / 100 + 1 : 1) *
                                            100
                                    ) / 100}{" "}
                                    €
                                </Typography>
                            </Box>
                            <Box style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }} p={1} display={"flex"} justifyContent={"space-between"}>
                                <Typography variant="caption">Preu 2 persones: </Typography>
                                <Typography variant="caption">
                                    {Math.round(
                                        Number(preus.find((p) => p.habitacio_id === 1 && p.persones === 2)?.preu) *
                                            (watch("tantpercent_torre") ? watch("tantpercent_torre") / 100 + 1 : 1) *
                                            100
                                    ) / 100}{" "}
                                    €
                                </Typography>
                            </Box>
                        </Box>
                        <Box mb={3}>
                            <TextField
                                error={Boolean(errors && errors?.tantpercent_montserrat)}
                                helperText={errors && errors?.tantpercent_montserrat?.message}
                                fullWidth
                                label={"Montserrat (%)"}
                                type="number"
                                variant="outlined"
                                {...register("tantpercent_montserrat")}
                            />
                            <Box style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }} p={1} display={"flex"} justifyContent={"space-between"}>
                                <Typography variant="caption">Preu 1 persona: </Typography>

                                <Typography variant="caption">
                                    {Math.round(
                                        Number(preus.find((p) => p.habitacio_id === 2 && p.persones === 1)?.preu) *
                                            (watch("tantpercent_montserrat") ? watch("tantpercent_montserrat") / 100 + 1 : 1) *
                                            100
                                    ) / 100}{" "}
                                    €
                                </Typography>
                            </Box>
                            <Box style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }} p={1} display={"flex"} justifyContent={"space-between"}>
                                <Typography variant="caption">Preu 2 persona: </Typography>

                                <Typography variant="caption">
                                    {Math.round(
                                        Number(preus.find((p) => p.habitacio_id === 2 && p.persones === 2)?.preu) *
                                            (watch("tantpercent_montserrat") ? watch("tantpercent_montserrat") / 100 + 1 : 1) *
                                            100
                                    ) / 100}{" "}
                                    €
                                </Typography>
                            </Box>
                        </Box>
                        <Box mb={3}>
                            <TextField
                                error={Boolean(errors && errors?.tantpercent_queralt)}
                                helperText={errors && errors?.tantpercent_queralt?.message}
                                fullWidth
                                label={"Queralt (%)"}
                                type="number"
                                variant="outlined"
                                {...register("tantpercent_queralt")}
                            />
                            <Box style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }} p={1} display={"flex"} justifyContent={"space-between"}>
                                <Typography variant="caption">Preu 1 persona: </Typography>

                                <Typography variant="caption">
                                    {Math.round(
                                        Number(preus.find((p) => p.habitacio_id === 3 && p.persones === 1)?.preu) *
                                            (watch("tantpercent_queralt") ? watch("tantpercent_queralt") / 100 + 1 : 1) *
                                            100
                                    ) / 100}{" "}
                                    €
                                </Typography>
                            </Box>
                            <Box style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }} p={1} display={"flex"} justifyContent={"space-between"}>
                                <Typography variant="caption">Preu 2 persona: </Typography>

                                <Typography variant="caption">
                                    {Math.round(
                                        Number(preus.find((p) => p.habitacio_id === 3 && p.persones === 2)?.preu) *
                                            (watch("tantpercent_queralt") ? watch("tantpercent_queralt") / 100 + 1 : 1) *
                                            100
                                    ) / 100}{" "}
                                    €
                                </Typography>
                            </Box>
                            <Box style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }} p={1} display={"flex"} justifyContent={"space-between"}>
                                <Typography variant="caption">Preu 3 persona: </Typography>

                                <Typography variant="caption">
                                    {Math.round(
                                        Number(preus.find((p) => p.habitacio_id === 3 && p.persones === 3)?.preu) *
                                            (watch("tantpercent_queralt") ? watch("tantpercent_queralt") / 100 + 1 : 1) *
                                            100
                                    ) / 100}{" "}
                                    €
                                </Typography>
                            </Box>
                        </Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) => {}}
                                    name="dosnits"
                                    {...register("dosnits")}
                                    defaultChecked={dies?.find((d) => moment(d.dia).format("YYYY-MM-DD") === moment(dia).format("YYYY-MM-DD"))?.dosnits}
                                />
                            }
                            label={<Typography>Mínim dues nits</Typography>}
                        />
                    </form>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={tancar} color="error" variant="contained">
                    Tancar
                </Button>
                <Button onClick={handleSubmit(enviar)} color="success" variant="contained">
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const getDateRange = (firstDate, lastDate) => {
    if (moment(firstDate, "YYYY-MM-DD").isSame(moment(lastDate, "YYYY-MM-DD"), "day")) return [lastDate];
    let date = firstDate;
    const dates = [date];
    do {
        date = moment(date).add(1, "day");
        dates.push(date.format("YYYY-MM-DD"));
    } while (moment(date).isBefore(lastDate));
    return dates;
};
