import {
    Box,
    Button,
    Container,
    Fade,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import ReservaState from '../../../components.js/ReservaState';
import Title from '../../../components.js/Title';
import XecSelect from '../../../components.js/XecSelect';
import { changeStateXec, get, getCodi } from '../../../database/API';
import parse from 'html-react-parser';
import { CheckCircle, Download, XCircle } from 'react-feather';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
    block: {
        boxShadow: '#00000020 1px 3px 20px 1px ',
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: 'white',
        overflow: 'hidden',
    },
    dates: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    hr: {
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        borderStyle: 'solid',
    },
    list: {
        paddingLeft: 20,
    },
}));

const CodiView = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { key } = useParams();
    const [open, setOpen] = useState(false);
    const [codi, setCodi] = useState();
    const [states, setStates] = useState();
    const [form, setForm] = useState();
    const [loading, setLoading] = useState(true);

    const formState = useForm({
        reValidateMode: 'onChange',
        defaultValues: { state_id: 0 },
    });

    useEffect(() => {
        const obtenir = async () => {
            const { codi } = await getCodi(key);
            const { resultat } = await get('states');
            formState.reset({ state_id: codi?.state_id });
            setStates(resultat);
            setCodi(codi);
            setLoading(false);
        };
        obtenir();
    }, [key]);

    const change = async (values) => {
        const message = await changeStateXec(values, key);
        const { codi } = await getCodi(key);
        setCodi(codi);
        enqueueSnackbar(message, {
            variant: 'success',
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Box mb={3}>
                        <Title
                            title={'Resum compra Xec Regal nº ' + codi?.id}
                            button={
                                <Box display="flex" alignItems="center">
                                    <Typography style={{ marginRight: 20 }}>
                                        {moment(codi?.created_at).format('LLL')}
                                    </Typography>

                                    <ReservaState state={codi?.state.nom} />
                                </Box>
                            }
                        />
                        <Typography variant="caption">
                            ID: {codi?.unique_id}
                        </Typography>
                    </Box>
                    <Fade in={!loading}>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                <Box className={classes.block}>
                                    <Box mb={3}>
                                        <Typography variant="h2">
                                            Client
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item md={6}>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Nom
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                style={{
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {codi?.nom}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                E-mail
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                style={{
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {codi?.email}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Telèfon
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                style={{
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {codi?.phone}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item md={6}>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Nom a qui va
                                                                adreçat
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                style={{
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {
                                                                    codi?.nom_regal
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Codi
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                style={{
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {codi?.codi}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                Gastat
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {codi?.gastat ? (
                                                                    <CheckCircle color="green" />
                                                                ) : (
                                                                    <XCircle color="red" />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className={classes.block}>
                                    <Box mb={3}>
                                        <Typography variant="h2">
                                            Xec
                                        </Typography>
                                    </Box>
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">
                                                        Nom xec
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {codi?.xec.nom}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">
                                                        Preu
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {codi?.xec.preu} €
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">
                                                        Descripció
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        <ul
                                                            className={
                                                                classes.list
                                                            }
                                                        >
                                                            {codi?.xec.descripcio
                                                                ?.split('\n')
                                                                ?.map((de) => (
                                                                    <li>
                                                                        {de}
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                <Box className={classes.block}>
                                    <Typography variant="h2">
                                        Informació
                                    </Typography>
                                    <Box m={3}></Box>
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">
                                                        Preu
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {codi?.xec?.preu} €
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">
                                                        Idioma
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                textTransform:
                                                                    'uppercase',
                                                            }}
                                                        >
                                                            {codi?.lang}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="left">
                                                        Descarrega el Xec Regal
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title="Descarrega el
                                                                Xec Regal"
                                                        >
                                                            <Button
                                                                LinkComponent={
                                                                    'a'
                                                                }
                                                                target="_blank"
                                                                download
                                                                href={`https://api.lacabanaberga.cat/storage/${codi?.pdf}`}
                                                            >
                                                                <Download />
                                                            </Button>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box className={classes.block}>
                                    <Typography variant="h2">
                                        Canvi d'estat
                                    </Typography>
                                    <Box m={3}></Box>
                                    <form
                                        onSubmit={formState.handleSubmit(
                                            change
                                        )}
                                    >
                                        <FormControl fullWidth>
                                            <InputLabel id={'state_id'}>
                                                Nou estat
                                            </InputLabel>
                                            <Select
                                                labelId="state_id"
                                                id="state_id"
                                                label="Nou estat"
                                                {...formState.register(
                                                    'state_id'
                                                )}
                                            >
                                                {states?.map((item) => {
                                                    return (
                                                        <MenuItem
                                                            value={item.id}
                                                        >
                                                            {item.nom}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                        <Box my={3}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                type="submit"
                                                style={{ zIndex: 0 }}
                                            >
                                                Canviar
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>
                                <Box className={classes.block}>
                                    <Typography variant="h2">
                                        Devolució
                                    </Typography>
                                    <Box m={3}></Box>
                                    {parse(form ? form.data : '')}
                                    <Box my={3}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={() => setOpen(true)}
                                            color="danger"
                                            style={{ zIndex: 0 }}
                                            disabled={codi?.state_id === 4}
                                        >
                                            Devolució
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Fade>
                </Grid>
            </Grid>
        </Container>
    );
};

export default CodiView;
