import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { addConcepte } from "../../../../database/API";
import { useParams } from "react-router";
import { Box } from "@mui/system";
import { Add } from "@mui/icons-material";
import ConcepteChip from "../../../../components.js/ConcepteChip";

function Conceptes({
    enqueueSnackbar,
    setUp,
    reserva,
    setDownloadLoading,
    loading,
}) {
    const { key } = useParams();
    const [open, setOpen] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        reValidateMode: "onChange",
    });

    const crear = async (values) => {
        setDownloadLoading(true);
        const nousConceptes = [
            ...JSON.parse(reserva?.conceptes ?? "[]"),
            values,
        ];

        const missatge = await addConcepte(nousConceptes, key);

        enqueueSnackbar(missatge, {
            variant: "success",
        });

        setOpen(false);

        setUp((prev) => prev + 1);
        setDownloadLoading(false);
    };

    const eliminar = async (value) => {
        setDownloadLoading(true);
        const nousConceptes = JSON.parse(reserva?.conceptes ?? "[]").filter(
            (item) => item.concepte !== value
        );
        // console.log(nousConceptes);
        const missatge = await addConcepte(nousConceptes, key);

        enqueueSnackbar(missatge, {
            variant: "success",
        });

        setUp((prev) => prev + 1);
        setDownloadLoading(false);
    };

    return (
        <Box>
            <Box>
                {JSON.parse(reserva?.conceptes ?? "[]")?.map((item) => (
                    <ConcepteChip
                        item={item}
                        handleDelete={() => eliminar(item.concepte)}
                    />
                ))}
            </Box>
            <Tooltip title="Afegir concepte">
                <Button onClick={() => setOpen(true)}>
                    <Add />
                </Button>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={handleSubmit(crear)}>
                    <DialogTitle id="alert-dialog-title">
                        Crear un nou concepte a la factura
                    </DialogTitle>
                    <DialogContent>
                        <Grid spacing={3} container mb={3} pt={2}>
                            <Grid item md={7}>
                                <TextField
                                    error={Boolean(errors && errors?.concepte)}
                                    helperText={
                                        errors && errors?.concepte?.message
                                    }
                                    fullWidth
                                    label={"Concepte"}
                                    name={"concepte"}
                                    type="text"
                                    variant="outlined"
                                    {...register("concepte")}
                                />
                            </Grid>
                            <Grid item md={5}>
                                <TextField
                                    error={Boolean(errors && errors?.preu)}
                                    helperText={errors && errors?.preu?.message}
                                    fullWidth
                                    label={"Preu amb IVA"}
                                    name={"preu"}
                                    type="number"
                                    variant="outlined"
                                    {...register("preu")}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Tancar</Button>
                        <Button autoFocus type="submit" variant="contained">
                            {loading ? (
                                <CircularProgress size={20} color="secondary" />
                            ) : (
                                "Crear concepte"
                            )}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
}

export default Conceptes;

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
    },
    dates: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));
