import React from 'react';
import { animated } from '@react-spring/web';
import Styles from '../views/public/Style';
import { Grid, Typography, Box } from '@mui/material';
import Dates from './Dates';
import { useTranslation } from 'react-i18next';

export default function ResumReserva({
    props,
    habitacio,
    dateF,
    dateS,
    preu,
    preuE,
}) {
    const classes = Styles();
    const { t } = useTranslation();

    return (
        <animated.div
            className={classes.datesCurt}
            style={{
                opacity: props.opacity,
            }}
        >
            <Box mb={3}>
                <Typography variant="h3">{t('Reserva')}</Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <Box
                        className={classes.dates}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Box p={2}>
                            <Typography variant="caption">
                                {habitacio?.target?.value.persones}{' '}
                                {t('persones')}
                            </Typography>
                            <Typography>
                                {habitacio?.target?.value.nom}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Dates
                        dateF={dateF}
                        dateS={dateS}
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box
                        className={classes.dates}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Box p={2}>
                            <Typography variant="caption">
                                {t('Preu')}
                            </Typography>
                            <Typography>
                                {preu !== 'NaN' ? preu + preuE : 0} €
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </animated.div>
    );
}
