import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Calendar, Code, File, Gift, UserCheck, Users } from "react-feather";
import NavBarItem from "./NavBarItem";
import { Box, Drawer, Hidden, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DocumentScanner, Home, Room, RoomPreferences } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: "64px !important",
        height: "calc(100% - 64px)",
    },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const items = [
        {
            href: "/admin/",
            icon: Home,
            title: "Inici",
        },
        {
            href: "/admin/reserves",
            icon: UserCheck,
            title: "Reserves",
        },

        {
            href: "/admin/persones",
            icon: Users,
            title: "Clients",
        },
        {
            href: "/admin/calendari",
            icon: Calendar,
            title: "Calendari",
        },
        {
            href: "/admin/codis",
            icon: Gift,
            title: "Compres Xecs",
        },
        {
            href: "/admin/factures",
            icon: File,
            title: "Factures",
        },
        {
            href: "/admin/habitacions",
            icon: RoomPreferences,
            title: "Preferències",
        },
        {
            href: "/admin/configcalendari",
            icon: RoomPreferences,
            title: "Config Calendari",
        },
    ];

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <Box p={2}>
                <List>
                    {items?.map((item) => (
                        <NavBarItem href={item.href} key={item.title} title={item.title} icon={item.icon} onClose={onMobileClose} />
                    ))}
                </List>
            </Box>
            <Box flexGrow={1} />
        </Box>
    );

    return (
        <div style={{ zIndex: 0 }}>
            <Hidden lgUp>
                <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
                    {content}
                </Drawer>
            </Hidden>
        </div>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
    onMobileClose: () => {},
    openMobile: false,
};

export default NavBar;
