import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useTranslation } from "react-i18next";
import unio from "../assets/img/unio_europea.png";
import plan from "../assets/img/plan.png";

const useStyles = makeStyles((theme) => ({
    overlay: {
        backgroundColor: "#000000",
        marginTop: 50,
        paddingTop: 50,
        paddingBottom: 40,
    },
    text: {
        color: "#777777 !important",
        fontSize: "13px !important",
    },
    textLink: {
        color: "#777777 !important",
        fontSize: "13px !important",
        textDecoration: "none",
        transition: ".2s",
        paddingRight: 8,
        paddingLeft: 8,
        "&:hover": {
            color: "white !important",
        },
    },
}));

const Footer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box className={classes.overlay}>
            <Container>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    alignItem="center"
                >
                    <Grid item md={4} paddingBottom={2} paddingTop={2}>
                        <Typography className={classes.text}>
                            {moment().format("Y")} La Cabana Hotel.{" "}
                            {t("Tots els drets són reservats.")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={8}
                        textAlign="center"
                        paddingBottom={2}
                        paddingTop={2}
                    >
                        <Box display="flex" justifyContent="center">
                            <Typography
                                component="a"
                                href="https://lacabanaberga.cat/nota-legal"
                                className={classes.textLink}
                                target="_blank"
                            >
                                {t("Avís Legal")}
                            </Typography>
                            <Typography
                                component="a"
                                href="https://lacabanaberga.cat/politica-de-cookies"
                                className={classes.textLink}
                                target="_blank"
                            >
                                {t("Política de Cookies")}
                            </Typography>
                            <Typography
                                component="a"
                                href="https://lacabanaberga.cat/politica-de-privacitat"
                                className={classes.textLink}
                                target="_blank"
                            >
                                {t("Política de Privacitat")}
                            </Typography>
                            <Typography
                                component="a"
                                href="https://lacabanaberga.cat/condicions-generals"
                                className={classes.textLink}
                                target="_blank"
                            >
                                {t("Condicions Generals")}
                            </Typography>
                            <Typography
                                component="a"
                                href="https://lacabanaberga.cat/politica-de-xarxes-socials"
                                className={classes.textLink}
                                target="_blank"
                            >
                                {t("Política de Xarxes Socials")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={4} textAlign="center">
                    <Typography className={classes.text}>
                        El proyecto de digitalización consistente en la
                        implantación del software GESVI ha sido financiado por
                        la Unión Europea – Next Generation EU
                    </Typography>
                    <Box display="flex" justifyContent={"center"} mt={2}>
                        <img src={unio} alt="Unió europea" width={200} />
                        <img src={plan} alt="Plà de recuperació" width={200} />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
