import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { changeNotes } from "../../../../database/API";
import { useForm } from "react-hook-form";
import CustomButton from "../../../../components.js/CustomButton";

function Notes({ reserva, setUp, loading, setLoading }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, reset } = useForm({ defaultValues: { notes: reserva?.notes } });

    useEffect(() => {
        reset(reserva);
    }, [reserva]);

    const editar = async (values) => {
        setLoading(true);
        const message = await changeNotes(values, reserva.id);
        enqueueSnackbar(message, {
            variant: "success",
        });
        setLoading(false);
        setUp((prev) => prev + 1);
    };

    return (
        <Box className={classes.block}>
            <form onSubmit={handleSubmit(editar)}>
                <Typography variant="h2">Notes</Typography>
                <Box m={3}></Box>
                <TextField {...register("notes")} label="Notes" multiline rows={10} fullWidth />
                <Box m={3}></Box>
                <CustomButton type={"submit"} title={"Guardar"} success />
            </form>
        </Box>
    );
}

export default Notes;

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
    },
    dates: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));
