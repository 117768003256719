import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import {
    Box,
    Button,
    CircularProgress,
    createTheme,
    Fade,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import { Add, Refresh } from "@mui/icons-material";
import { useNavigate } from "react-router";
import FacturesOptions from "./Table/FacturesOptions";
import Columns from "./Table/FacturesColumns";
import { getAdmin } from "../../../database/API";
import Title from "../../../components.js/Title";
import moment from "moment";
import CustomButton from "../../../components.js/CustomButton";

const FacturesTable = (props) => {
    const classes = useStyles();
    const { id } = props;
    const [factures, setFactures] = useState();
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const columns = Columns(factures);
    const options = FacturesOptions(factures);
    const navigate = useNavigate();
    const [trimestre, setTrimestre] = useState("");
    const [any, setAny] = useState("");
    const [anys, setAnys] = useState([]);

    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            boxShadow: "#00000020 1px 3px 20px 1px ",
                            borderRadius: 30,
                            padding: 30,
                            zIndex: 0,
                            marginBottom: 80,
                            backgroundColor: "white",
                            overflow: "hidden",
                            position: "relative",
                        },
                    },
                },
            },
        });

    useEffect(() => {
        const get = async () => {
            const { resultat } = await getAdmin("factures");
            setFactures(resultat);
            setLoading(false);
        };

        let res = [];

        for (
            let index = Number(moment().format("YYYY"));
            index > Number(moment().format("YYYY")) - 10;
            index--
        ) {
            res.push(index);
        }

        setAnys(res);

        get();
    }, [id]);

    const refresca = () => {
        const llargada = factures?.length;
        setLoading(true);
        const get = async () => {
            const results = await getAdmin("factures");
            setFactures(results.resultat);
            setLoading(false);
            const llargada_nova = results.resultat?.length;
            if (llargada_nova - llargada === 0) {
                enqueueSnackbar("No hi ha noves factures", {
                    variant: "success",
                });
            } else {
                enqueueSnackbar(
                    "S'ha afegit " + (llargada_nova - llargada) + " factura/es",
                    {
                        variant: "success",
                    }
                );
            }
        };
        get();
    };

    const filtrar = async () => {
        setLoading(true);
        const results = await getAdmin(
            "factures?trimestre=" + trimestre + "&any=" + any
        );
        setFactures(results.resultat);
        setLoading(false);
    };

    return (
        <>
            <Title
                title="Factures"
                button={
                    <Box>
                        <Tooltip title="Refrescar">
                            <IconButton onClick={() => refresca()}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            />
            <Box pt={2} spacing={3}>
                {!loading ? (
                    <Fade in={!loading}>
                        <div>
                            <ThemeProvider theme={getMuiTheme()}>
                                <Box className={classes.block}>
                                    <Typography variant="h3" mb={2}>
                                        Filtres
                                    </Typography>
                                    <Grid spacing={3} container>
                                        <Grid item md={5}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">
                                                    Trimestre
                                                </InputLabel>
                                                <Select
                                                    size="small"
                                                    label="Trimestre"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={trimestre}
                                                    onChange={(event) => {
                                                        setTrimestre(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <MenuItem value="1">
                                                        1T
                                                    </MenuItem>
                                                    <MenuItem value="2">
                                                        2T
                                                    </MenuItem>
                                                    <MenuItem value="3">
                                                        3T
                                                    </MenuItem>
                                                    <MenuItem value="4">
                                                        4T
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={5}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">
                                                    Any
                                                </InputLabel>
                                                <Select
                                                    size="small"
                                                    label="Any"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={any}
                                                    onChange={(event) => {
                                                        setAny(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    {anys?.map((item) => (
                                                        <MenuItem value={item}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={2}>
                                            <Button
                                                variant="contained"
                                                onClick={filtrar}
                                                fullWidth
                                                style={{ zIndex: 0 }}
                                            >
                                                Filtrar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <MUIDataTable
                                    data={factures}
                                    columns={columns}
                                    options={options}
                                />
                            </ThemeProvider>
                        </div>
                    </Fade>
                ) : (
                    <CircularProgress />
                )}
            </Box>
        </>
    );
};

export default FacturesTable;

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },
}));
