import React from "react";
import { TableCell, TableRow } from "@mui/material";

const Dada = ({ title, value, button }) => {
    return (
        <TableRow>
            <TableCell align="left" verticalAlign="top">
                {title}
            </TableCell>
            <TableCell
                align="right"
                style={{
                    fontWeight: 600,
                }}
            >
                {value && value}
                {button && button}
            </TableCell>
        </TableRow>
    );
};

export default Dada;
