import { Box, Chip } from "@mui/material";

const ConcepteChip = ({ item, handleDelete }) => {
    return (
        <Box my={2}>
            <Chip
                label={item.concepte + " + " + item.preu + " €"}
                onDelete={handleDelete}
            />
            <br />
        </Box>
    );
};

export default ConcepteChip;
