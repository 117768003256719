import { IconButton } from "@mui/material";
import moment from "moment";
import { Download } from "react-feather";

const Columns = (orders) => {
    const columns = [
        {
            name: "reserva.id",
            label: "Nº ",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => value,
            },
        },
        {
            name: "reserva.nom_contacte",
            label: "Nom",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => value,
            },
        },
        {
            name: "reserva.email_contacte",
            label: "E-mail",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => value,
            },
        },

        {
            name: "trimestre",
            label: "Trimestre",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
                customBodyRender: (value) => value + "T",
            },
        },
        {
            name: "created_at",
            label: "Data creació",
            options: {
                filter: false,
                sort: true,
                sortOrder: "desc",
                customBodyRender: (value) =>
                    moment(new Date(value)).format("DD/MM/YYYY HH:mm"),
            },
        },

        {
            name: "reserva.preu",
            label: "Preu",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => value + "€",
            },
        },
        {
            name: "pdf",
            label: "Accions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value) => {
                    return (
                        <IconButton href={value} target="_blank">
                            <Download />
                        </IconButton>
                    );
                },
            },
        },
    ];
    return columns;
};

export default Columns;
