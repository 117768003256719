import { Box, Container, Fade } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Title from "../../components.js/Title";
import { calendarisBooking, getAdmin } from "../../database/API";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
import Loading from "../../components.js/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 80,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },
    calendar: {
        fontFamily: "Open Sans",
        color: theme.palette.text.primary,
        marginBottom: 100,
        "& .fc-button-primary": {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            "&:hover": {
                backgroundColor: theme.palette.primary.hover,
                borderColor: theme.palette.primary.hover,
            },
        },
        "& .fc-scroller-harness-liquid": {
            height: "100%",
        },
    },
}));

const CalendariView = () => {
    const classes = useStyles();
    const [reserves, setReserves] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { resultat } = await getAdmin("reserves");
            const habitacions = await getAdmin("habitacions");
            const getCalendars = async () => {
                let events = [];
                resultat.map((item) =>
                    events.push({
                        title:
                            item.id +
                            " - " +
                            item.habitacio?.nom +
                            " (" +
                            item?.nom_contacte +
                            ")",
                        start: item.dateS,
                        end: moment(item.dateF)
                            .add("1", "day")
                            .format("YYYY-MM-DD"),
                        color:
                            item.state.id === 4 ? "red" : item.habitacio.color,
                        url: "/admin/" + item.unique_id,
                    })
                );
                habitacions.resultat.map(async (hab) => {
                    if (hab.calendari_booking) {
                        const result_booking = await calendarisBooking(hab?.id);
                        console.log(result_booking);
                        result_booking.map((item) =>
                            events.push({
                                title:
                                    hab.nom +
                                    " - " +
                                    item.summary.replace("CLOSED", "BOOKING"),
                                start: moment(
                                    item.startDate,
                                    "YYYYMMDD"
                                ).format("YYYY-MM-DD"),
                                end: moment(item.endDate, "YYYYMMDD")
                                    .add(1, "day")
                                    .format("YYYY-MM-DD"),
                                color: "#003580",
                            })
                        );
                    }
                });
                return events;
            };

            const cal = await getCalendars();
            setTimeout(() => {
                setReserves(cal);
                setLoading(false);
            }, 5000);
        };

        get();
    }, []);

    useEffect(() => {
        console.log(reserves);
    }, [reserves]);

    return (
        <Container maxWidth={false} className={classes.container}>
            <Box>
                <Box pt={2} spacing={3}>
                    <Title title="Calendari" />
                    <Box my={4} />
                    <Loading loading={loading}>
                        <Box>
                            <Box my={3} />
                            <Fade in={!loading}>
                                <div className={classes.calendar}>
                                    <FullCalendar
                                        plugins={[dayGridPlugin]}
                                        initialView="dayGridMonth"
                                        events={reserves}
                                        locale={"ca"}
                                        firstDay={1}
                                        buttonText={{ today: "avui" }}
                                        displayEventTime={false}
                                    />
                                </div>
                            </Fade>
                        </Box>
                    </Loading>
                </Box>
            </Box>
        </Container>
    );
};

export default CalendariView;
