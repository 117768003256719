import { ArrowRightAlt, CircleSharp } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React from "react";
import { Download, Eye, Key } from "react-feather";
import clsx from "clsx";
import { useNavigate } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import ReservaState from "./ReservaState";
import { downloadTXT } from "../database/API";
import ExtraChip from "./ExtraChip";

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },
    content: {
        padding: 30,
    },
    dates: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    alarm: {
        border: "3px solid " + theme.palette.primary.main,
    },
    key: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    habitacio: {
        alignItems: "center",
        display: "flex",
    },
    icon: {
        paddingRight: 10,
        paddingLeft: 10,
    },
    header: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        padding: 15,
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
    },
    buttons: {
        justifyContent: "space-between",
        width: 200,
        display: "flex",
    },
}));

const ReservaFutura = ({ reserva, setUpdate }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const alarm =
        moment().format("YYYY-MM-DD") ===
        moment(reserva.dateS).format("YYYY-MM-DD");

    const exportar = () => {
        enqueueSnackbar("S'ha copiat el còdi", {
            variant: "success",
        });
    };

    const download = async () => {
        setUpdate((prev) => prev + 1);
        await downloadTXT(reserva.id);
    };

    console.log(reserva);

    return (
        <Box className={clsx(classes.block, alarm && classes.alarm)}>
            <Box>
                <Box className={classes.header}>
                    <Box display={"flex"}>
                        <ReservaState state={reserva.state.nom} />
                        <Box mx={1} />
                        <Box>
                            <Typography
                                variant="h3"
                                style={{ display: "flex" }}
                            >
                                Reserva nº {reserva.id} - {reserva.preu} €
                                <Avatar
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        fontSize: 10,
                                        marginLeft: 2,
                                    }}
                                >
                                    {reserva?.lang}
                                </Avatar>
                            </Typography>
                            <Typography variant="caption">
                                ID: {reserva.unique_id}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.buttons}>
                        <CopyToClipboard text={reserva.codi} onCopy={exportar}>
                            <Tooltip title="Clica per a copiar el còdi">
                                <Button color="secondary" variant="contained">
                                    <Typography className={classes.key}>
                                        <Key
                                            style={{
                                                width: 18,
                                                marginRight: 5,
                                            }}
                                        />
                                        {reserva?.codi}
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </CopyToClipboard>
                        <Tooltip title="Descarregar TXT">
                            <IconButton color="primary" onClick={download}>
                                <Download />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Veure reserva">
                            <IconButton
                                color="primary"
                                onClick={() =>
                                    navigate(`/admin/${reserva.unique_id}`)
                                }
                            >
                                <Eye />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Grid container className={classes.content}>
                    <Grid item md={8}>
                        <Grid container spacing={3} className={classes.content}>
                            <Grid item md={6}>
                                <Typography variant="caption">
                                    Habitació
                                </Typography>
                                <Typography className={classes.habitacio}>
                                    <CircleSharp
                                        style={{
                                            fill: reserva?.habitacio.color,
                                            marginRight: 10,
                                        }}
                                    />
                                    {reserva?.habitacio.nom}
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="caption">Nom</Typography>
                                <Typography>
                                    {reserva?.persones[0]?.nom
                                        ? reserva?.persones[0]?.nom +
                                          " " +
                                          reserva?.persones[0]?.cognom1 +
                                          " " +
                                          reserva?.persones[0]?.cognom2
                                        : reserva?.nom_contacte}
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="caption">
                                    Data entrada
                                </Typography>
                                <Typography className={classes.habitacio}>
                                    {reserva.dateS
                                        ? moment(reserva.dateS).format(
                                              "DD/MM/YYYY"
                                          )
                                        : "Selecciona data"}
                                    <ArrowRightAlt className={classes.icon} />
                                    {reserva.dateF
                                        ? moment(reserva.dateF).format(
                                              "DD/MM/YYYY"
                                          )
                                        : "Selecciona data"}
                                </Typography>
                            </Grid>

                            <Grid item md={6}>
                                <Typography variant="caption">
                                    E-mail
                                </Typography>
                                <Typography>
                                    {reserva?.persones?.[0]?.email
                                        ? reserva?.persones?.[0]?.email
                                        : reserva?.email_contacte}
                                </Typography>
                            </Grid>

                            <Grid item md={6}>
                                <Typography variant="caption">
                                    Persones
                                </Typography>
                                <Typography>
                                    {reserva?.persones.length === 0
                                        ? reserva?.persones_val
                                        : reserva?.persones.length}{" "}
                                    persona/es
                                </Typography>
                            </Grid>

                            <Grid item md={6}>
                                <Typography variant="caption">
                                    Telèfon
                                </Typography>
                                <Typography>
                                    {reserva?.persones?.[0]?.phone
                                        ? reserva?.persones?.[0]?.phone
                                        : reserva?.phone_contacte}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4}>
                        <Box p={3} style={{ lineHeight: "10px" }}>
                            {reserva?.codi_xec ? (
                                <>
                                    <Typography variant="caption">
                                        Xec
                                    </Typography>
                                    <Box my={2}>
                                        <Chip
                                            style={{
                                                paddingTop: 12,
                                                paddingBottom: 12,
                                            }}
                                            onClick={() =>
                                                navigate(
                                                    `/admin/codis/${reserva?.codi_xec?.unique_id}`
                                                )
                                            }
                                            label={reserva?.codi_xec?.xec?.nom}
                                            avatar={
                                                <Avatar>
                                                    {
                                                        reserva?.codi_xec?.xec
                                                            .nits
                                                    }
                                                </Avatar>
                                            }
                                        />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Typography variant="caption">
                                        Extres
                                    </Typography>
                                    {JSON.parse(reserva?.extra)?.map(
                                        (item) =>
                                            item.checked && (
                                                <ExtraChip item={item} />
                                            )
                                    )}
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ReservaFutura;
