import {
    Box,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Save } from "react-feather";
import { updateDescompte } from "../../../../database/API";
import Dada from "./Dada";

function Descompte({ reserva, setUp }) {
    const [descompte, setDescompte] = useState(reserva?.descompte);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setDescompte(reserva?.descompte);
    }, [reserva]);

    const guardar = async () => {
        const message = await updateDescompte(
            { descompte: descompte },
            reserva?.unique_id
        );
        setUp((prev) => prev + 1);
        enqueueSnackbar(message, {
            variant: "success",
        });
    };

    return (
        <Dada
            title="Descompte"
            button={
                <Box
                    display="flex"
                    alignItems={"flex-end"}
                    flexDirection="row"
                    justifyContent={"flex-end"}
                >
                    <FormControl
                        variant="standard"
                        sx={{
                            width: "10ch",
                        }}
                    >
                        <Input
                            id="standard-adornment-weight"
                            value={descompte}
                            onChange={(val) => setDescompte(val.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    %
                                </InputAdornment>
                            }
                            aria-describedby="standard-weight-helper-text"
                            inputProps={{
                                "aria-label": "weight",
                            }}
                        />
                    </FormControl>
                    <IconButton onClick={guardar}>
                        <Save />
                    </IconButton>
                </Box>
            }
        />
    );
}

export default Descompte;
