import React from 'react';
import {
    Box,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
} from '@mui/material';

const ParamField = ({ item, form }) => {
    return (
        <Box>
            {item.type === 'checkbox' ? (
                <FormControlLabel
                    control={
                        <Checkbox
                            name={item.sku}
                            {...form.register(item.sku)}
                            type="checkbox"
                            defaultChecked={item.value === '1' ? true : false}
                        />
                    }
                    label={<Typography>{item.nom}</Typography>}
                />
            ) : (
                <TextField
                    key={item.sku}
                    fullWidth
                    label={item.nom}
                    name={item.sku}
                    type="text"
                    variant="outlined"
                    {...form.register(item.sku, {
                        value: item.value,
                        valueAsNumber: true,
                    })}
                />
            )}
        </Box>
    );
};

export default ParamField;
