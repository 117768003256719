import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import React from 'react';
import { Minus, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Styles from '../views/public/Style';

const ExtraSmall = ({ setSelExtras, item }) => {
    const classes = Styles();
    const { t, i18n } = useTranslation();

    return (
        <Grid item md={6}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.extraBox}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e) => {
                                setSelExtras((prevState) =>
                                    prevState.map((ex) =>
                                        ex.id === item.id
                                            ? {
                                                  ...ex,
                                                  checked: !ex.checked,
                                              }
                                            : ex
                                    )
                                );
                            }}
                            name={item.nom}
                        />
                    }
                    label={
                        <Box className={classes.extra}>
                            <Typography variant="body1">
                                {i18n.language === 'ca'
                                    ? item.nom
                                    : i18n.language === 'es'
                                    ? item.nom_es
                                    : item.nom_en}{' '}
                                + {item.preu * item.count} €
                            </Typography>
                            <Typography variant="caption">
                                {i18n.language === 'ca'
                                    ? item.descripcio
                                    : i18n.language === 'es'
                                    ? item.descripcio_es
                                    : item.descripcio_en}
                            </Typography>
                        </Box>
                    }
                />
                <Box className={classes.counters}>
                    <IconButton
                        color="primary"
                        size="medium"
                        variant="contained"
                        onClick={(e) => {
                            setSelExtras((prevState) =>
                                prevState.map((ex) =>
                                    ex.count !== 0
                                        ? ex.id === item.id
                                            ? {
                                                  ...ex,
                                                  count: ex.count - 1,
                                              }
                                            : ex
                                        : ex
                                )
                            );
                        }}
                    >
                        <Minus size={12} />
                    </IconButton>
                    <Typography variant="body1" style={{ color: 'black' }}>
                        {item.count}
                    </Typography>

                    <IconButton
                        color="primary"
                        size="medium"
                        variant="contained"
                        onClick={(e) => {
                            setSelExtras((prevState) =>
                                prevState.map((ex) =>
                                    ex.id === item.id
                                        ? {
                                              ...ex,
                                              count: ex.count + 1,
                                          }
                                        : ex
                                )
                            );
                        }}
                    >
                        <Plus size={12} />
                    </IconButton>
                </Box>
            </Box>
        </Grid>
    );
};

export default ExtraSmall;
