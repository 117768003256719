import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { getAdmin } from '../../../../database/API';
import { Box, CircularProgress, createTheme, Fade } from '@mui/material';
import { makeStyles, ThemeProvider } from '@mui/styles';
import Title from '../../../../components.js/Title';
import Columns from './CodisColumns';
import CodisOptions from './CodisOptions';

makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    block: {
        boxShadow: '#00000020 1px 3px 20px 1px ',
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 80,
        backgroundColor: 'white',
        overflow: 'hidden',
        position: 'relative',
    },
}));

const CodisTable = () => {
    const [codis, setCodis] = useState();
    const [loading, setLoading] = useState(true);
    const columns = Columns(codis);
    const options = CodisOptions(codis);

    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            boxShadow: '#00000020 1px 3px 20px 1px ',
                            borderRadius: 30,
                            padding: 30,
                            zIndex: 0,
                            marginBottom: 80,
                            backgroundColor: 'white',
                            overflow: 'hidden',
                            position: 'relative',
                        },
                    },
                },
            },
        });

    useEffect(() => {
        const get = async () => {
            const { resultat } = await getAdmin('codis');
            setCodis(resultat);
            setLoading(false);
        };

        get();
    }, []);

    return (
        <>
            <Title title="Xecs regals comprats" />
            <Box pt={2} spacing={3}>
                {!loading ? (
                    <Fade in={!loading}>
                        <div>
                            <ThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    data={codis}
                                    columns={columns}
                                    options={options}
                                />
                            </ThemeProvider>
                        </div>
                    </Fade>
                ) : (
                    <CircularProgress />
                )}
            </Box>
        </>
    );
};

export default CodisTable;
