import { IconButton } from '@mui/material';
import { CheckCircle, Eye, XCircle } from 'react-feather';
import { useNavigate } from 'react-router';
import ReservaState from '../../../../components.js/ReservaState';

const Columns = (orders) => {
    const navigate = useNavigate();
    const columns = [
        {
            name: 'unique_id',
            label: 'ID',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'id',
            label: 'Nº',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'state.nom',
            label: 'Estat',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return <ReservaState state={value} />;
                },
            },
        },
        {
            name: 'nom',
            label: 'Nom',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'email',
            label: 'E-mail',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'nom_regal',
            label: 'Nom regalat',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'xec.nom',
            label: 'Xec',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'phone',
            label: 'Telèfon',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'gastat',
            label: 'Gastat',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return value ? (
                        <CheckCircle color="green" />
                    ) : (
                        <XCircle color="red" />
                    );
                },
            },
        },
        {
            name: 'unique_id',
            label: 'Accions',
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <IconButton
                            onClick={() =>
                                navigate(
                                    `/admin/codis/${orders[dataIndex].unique_id}`
                                )
                            }
                        >
                            <Eye />
                        </IconButton>
                    );
                },
            },
        },
    ];
    return columns;
};

export default Columns;
