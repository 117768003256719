import { Adjust, Link } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '../views/public/Style';

export default function Condicions({ showNits }) {
    const classes = Styles();
    const { t } = useTranslation();
    return (
        <Box className={classes.reserva}>
            <Typography variant="h4">
                {t('Notes i condicions generals')}
            </Typography>
            <Box my={2} />
            {showNits && (
                <Typography variant="body2">
                    <Adjust fontSize={'10px'} /> {t('Estada mínima: dues nits')}
                </Typography>
            )}

            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t("Els preus de l'allotjament inclouen l'esmorzar")}
            </Typography>
            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t("Les reserves s'han de realitzar exclusivament online")}
            </Typography>
            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t(
                    "L'entrada a les habitacions es farà a partir de les 13.00 h"
                )}
            </Typography>
            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t('La sortida de les habitacions es farà abans de 11.00 h')}
            </Typography>

            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t(
                    "No s'accepta cap tipus d'animal de companyia a l'habitació ni a les instal·lacions"
                )}
            </Typography>

            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t(
                    "No pot entrar a l'habitació cap persona que no estigui registrada"
                )}
            </Typography>
            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t("No es pot superar l'aforament de l'habitació")}
            </Typography>
            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t('No ens fem responsables de les pertinences dels hostes')}
            </Typography>
            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t(
                    "No ens fem responsables de les pertinences dels hostes oblidades a la caixa de seguretat de l'habitació"
                )}
            </Typography>
            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t('Cal respectar les instal·lacions')}
            </Typography>
            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t(
                    'Està totalment prohibit fumar a tot el recinte de La Cabana (excepte en exteriors)'
                )}
            </Typography>
            <Typography variant="body2">
                <Adjust fontSize={'10px'} />{' '}
                {t(
                    "La cancel·lació del servei es podrà realitzar, sense cap cost adicional, fins a 24 hores abans de la data de la reserva. Un cop superat aquest període el cost de cancel·lació serà l'import íntegre de l'estada."
                )}
            </Typography>
            <Typography variant="body2">
                <a
                    href="https://lacabanaberga.cat/condicions-generals"
                    alt="Condicions generals"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Link fontSize={'10px'} /> {t('Més informació')}
                </a>
            </Typography>
        </Box>
    );
}
