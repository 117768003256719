import {
    Box,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Edit } from 'react-feather';
import XecEdit from './XecEdit';

const useStyles = makeStyles((theme) => ({
    item: { paddingTop: 25 },
    block: {
        boxShadow: '#00000020 1px 3px 20px 1px ',
        borderRadius: 30,
        zIndex: 0,
        backgroundColor: 'white',
        overflow: 'hidden',
        position: 'relative',
    },
}));

const XecElement = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [desc, setDesc] = useState([]);
    const { item, setUpdate } = props;

    useEffect(() => {
        let de = item?.descripcio.split('\n');
        setDesc(de);
    }, [item]);

    return (
        <Grid item md={4} xs={12} key={item.id}>
            <Box className={classes.block}>
                <CardHeader
                    className={classes.header}
                    action={
                        <>
                            <IconButton onClick={() => setOpen(true)}>
                                <Edit />
                            </IconButton>
                        </>
                    }
                    title={item.nom}
                />
                <CardContent>
                    <Grid
                        container
                        justify="space-between"
                        alignItems="flex-end"
                    >
                        <Grid item md={6}>
                            <Typography
                                variant="body2"
                                component="p"
                                style={{ whiteSpace: 'pre-line' }}
                            >
                                {desc}
                            </Typography>
                            <Typography variant="body1" component="p">
                                {item.nits && item.nits} nits
                            </Typography>
                        </Grid>
                        <Grid item md={6} style={{ textAlign: 'right' }}>
                            <Typography variant="body1" component="p">
                                {item.preu} €
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Box>
            <XecEdit
                item={item}
                openXec={open}
                setOpenXec={setOpen}
                setUpdate={setUpdate}
            />
        </Grid>
    );
};

export default XecElement;
