import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { campsFactura } from "../../../../database/API";
import { useParams } from "react-router";

function CrearFactura({
    setLoading,
    setOpen,
    open,
    enqueueSnackbar,
    setUp,
    reserva,
}) {
    const { key } = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        reValidateMode: "onChange",
        defaultValues: { reserva },
    });

    useEffect(() => {
        reset(reserva);
    }, [open]);

    const crear = async (values) => {
        setLoading(true);
        const missatge = await campsFactura(values, key);
        enqueueSnackbar(missatge, {
            variant: "success",
        });
        setLoading(false);
        setUp((prev) => prev + 1);
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <form onSubmit={handleSubmit(crear)}>
                <DialogTitle id="alert-dialog-title">
                    Ompla les dades per crear una factura
                </DialogTitle>
                <DialogContent>
                    <Grid spacing={3} container mb={3} pt={2}>
                        <Grid item md={6}>
                            <TextField
                                error={Boolean(errors && errors?.rao)}
                                helperText={errors && errors?.rao?.message}
                                fullWidth
                                label={"Raó social"}
                                name={"rao"}
                                type="text"
                                variant="outlined"
                                {...register("rao")}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                error={Boolean(errors && errors?.nif)}
                                helperText={errors && errors?.nif?.message}
                                fullWidth
                                label={"NIF"}
                                name={"nif"}
                                type="text"
                                variant="outlined"
                                {...register("nif")}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={3} container mb={3}>
                        <Grid item md={6}>
                            <TextField
                                error={Boolean(errors && errors?.adreca)}
                                helperText={errors && errors?.adreca?.message}
                                fullWidth
                                label={"Adreça"}
                                name={"adreca"}
                                type="text"
                                variant="outlined"
                                {...register("adreca")}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                error={Boolean(errors && errors?.codi_postal)}
                                helperText={
                                    errors && errors?.codi_postal?.message
                                }
                                fullWidth
                                label={"Codi Postal"}
                                name={"codi_postal"}
                                type="text"
                                variant="outlined"
                                {...register("codi_postal")}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={3} container mb={3}>
                        <Grid item md={6}>
                            <TextField
                                error={Boolean(errors && errors?.poblacio)}
                                helperText={errors && errors?.poblacio?.message}
                                fullWidth
                                label={"Població"}
                                name={"poblacio"}
                                type="text"
                                variant="outlined"
                                {...register("poblacio")}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                error={Boolean(errors && errors?.pais)}
                                helperText={errors && errors?.pais?.message}
                                fullWidth
                                label={"País"}
                                name={"pais"}
                                type="text"
                                variant="outlined"
                                {...register("pais")}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Tancar</Button>
                    <Button autoFocus type="submit" variant="contained">
                        Guardar dades
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default CrearFactura;

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
    },
    dates: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));
