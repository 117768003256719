import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    Paper,
    Box,
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { createExtra, updateExtra } from '../database/API';
import { useSnackbar } from 'notistack';

const ExtraEdit = ({ item, openExtra, setOpenExtra, setUpdate }) => {
    const { enqueueSnackbar } = useSnackbar();

    const guardar = async (values) => {
        let message = '';
        if (item) {
            message = await updateExtra(values, item.id);
        } else {
            message = await createExtra(values);
        }
        enqueueSnackbar(message, {
            variant: 'success',
        });
        setUpdate((prev) => prev + 1);
        setOpenExtra(false);
    };
    return (
        <Dialog
            open={openExtra}
            onClose={() => setOpenExtra(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'lg'}
        >
            <DialogTitle id="alert-dialog-title">
                {item ? 'Editar' : 'Crear'} {item?.nom}
            </DialogTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    nom: item?.nom,
                    nom_es: item?.nom_es,
                    nom_en: item?.nom_en,
                    preu: item?.preu,
                    descripcio: item?.descripcio,
                    descripcio_es: item?.descripcio_es,
                    descripcio_en: item?.descripcio_en,
                }}
                validationSchema={Yup.object().shape({
                    nom: Yup.string().max(255).required('El nom és obligatòri'),
                    preu: Yup.number().required('El preu és obligatori'),
                })}
                onSubmit={(values, actions) => {
                    guardar(values, actions);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    resetForm,
                    isSubmitting,
                    setFieldValue,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item md={12}>
                                    <Box
                                        p={3}
                                        style={{
                                            border: '1px solid #cacaca',
                                            borderRadius: 10,
                                        }}
                                    >
                                        <TextField
                                            error={Boolean(
                                                touched.nom && errors.nom
                                            )}
                                            fullWidth
                                            helperText={
                                                touched.nom && errors.nom
                                            }
                                            label={'Nom'}
                                            margin="normal"
                                            name="nom"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.nom}
                                            variant="outlined"
                                        />
                                        <TextField
                                            error={Boolean(
                                                touched.nom_es && errors.nom_es
                                            )}
                                            fullWidth
                                            helperText={
                                                touched.nom_es && errors.nom_es
                                            }
                                            label={'Nombre castellano'}
                                            margin="normal"
                                            name="nom_es"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.nom_es}
                                            variant="outlined"
                                        />
                                        <TextField
                                            error={Boolean(
                                                touched.nom_en && errors.nom_en
                                            )}
                                            fullWidth
                                            helperText={
                                                touched.nom_en && errors.nom_en
                                            }
                                            label={'Nom anglès'}
                                            margin="normal"
                                            name="nom_en"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.nom_en}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box my={3} />
                            <Grid container spacing={3}>
                                <Grid item md={12}>
                                    <Box
                                        p={3}
                                        style={{
                                            border: '1px solid #cacaca',
                                            borderRadius: 10,
                                        }}
                                    >
                                        <TextField
                                            error={Boolean(
                                                touched.descripcio &&
                                                    errors.descripcio
                                            )}
                                            fullWidth
                                            helperText={
                                                touched.descripcio &&
                                                errors.descripcio
                                            }
                                            label={'Descripció'}
                                            multiline
                                            margin="normal"
                                            name="descripcio"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.descripcio}
                                            variant="outlined"
                                        />

                                        <TextField
                                            error={Boolean(
                                                touched.descripcio_es &&
                                                    errors.descripcio_es
                                            )}
                                            fullWidth
                                            helperText={
                                                touched.descripcio_es &&
                                                errors.descripcio_es
                                            }
                                            label={'Descripción castellano'}
                                            multiline
                                            margin="normal"
                                            name="descripcio_es"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.descripcio_es}
                                            variant="outlined"
                                        />

                                        <TextField
                                            error={Boolean(
                                                touched.descripcio_en &&
                                                    errors.descripcio_en
                                            )}
                                            fullWidth
                                            helperText={
                                                touched.descripcio_en &&
                                                errors.descripcio_en
                                            }
                                            label={'Descripció anglès'}
                                            multiline
                                            margin="normal"
                                            name="descripcio_en"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="text"
                                            value={values.descripcio_en}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>

                                <Grid item md={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.preu && errors.preu
                                        )}
                                        fullWidth
                                        helperText={touched.preu && errors.preu}
                                        label={'Preu'}
                                        margin="normal"
                                        name="preu"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.preu}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenExtra(false)}>
                                Tancar
                            </Button>
                            <Button type="submit" autoFocus>
                                Guardar
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default ExtraEdit;
