import {
    Box,
    CircularProgress,
    Container,
    Fade,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../../theme/style.css";
import {
    calendaris,
    checkinReserva,
    codiCorrecte,
    get,
} from "../../database/API";
import "moment/locale/ca";
import Styles from "./Style";
import { animated, useSpring } from "@react-spring/web";
import CustomButton from "../../components.js/CustomButton";
import { useSnackbar } from "notistack";
import ComGastar from "../../components.js/ComGastar";
import FormulariReserva from "../../components.js/FormulariReserva";
import Condicions from "../../components.js/Condicions";
import { useParams } from "react-router";
import { scroller, Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import ComCheckin from "../../components.js/ComChekin";
import ResumReserva from "../../components.js/ResumReserva";
import ReservaCheckin from "../../components.js/ReservaCheckin";

const CheckIn = () => {
    const classes = Styles();
    const [loading, setLoading] = useState(false);
    const { key } = useParams();
    const [numReserva, setNumReserva] = useState("");
    const [reserva, setReserva] = useState();
    const [showContinuarCodi, setShowContinuarCodi] = useState(false);
    const refInfo = useRef();
    const refXecs = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        if (key !== "") {
            setNumReserva(key);
        }
    }, [key]);

    const comprovar = async () => {
        setLoading(true);
        const { message, success, reserva } = await checkinReserva(numReserva);
        if (success === "success") {
            setReserva(reserva);
            setShowContinuarCodi(true);
        }
        enqueueSnackbar(message, {
            variant: success,
        });
        setLoading(false);
    };

    const props = useSpring({
        from: {
            opacity: 0,
            opacityD: 1,
            height: refXecs?.current?.clientHeight,
            heightD: refInfo?.current?.clientHeight,
        },
        opacity: showContinuarCodi ? 0 : 1,
        height: showContinuarCodi ? 0 : refXecs?.current?.clientHeight,
        opacityD: showContinuarCodi ? 1 : 0,
        heightD: showContinuarCodi ? refInfo?.current?.clientHeight : 0,
    });

    return (
        <Box className={classes.main}>
            <Fade in={loading}>
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            </Fade>

            <ComCheckin />

            <Container>
                <animated.div
                    className={classes.first}
                    style={{
                        height: props.height,
                        opacity: props.opacity,
                    }}
                >
                    <Container maxWidth="sm">
                        <Box className={classes.reserva}>
                            <Box
                                justifyContent="center"
                                display="flex"
                                flexDirection="column"
                            >
                                <TextField
                                    onChange={(value) =>
                                        setNumReserva(value.target.value)
                                    }
                                    name="numReserva"
                                    type="text"
                                    variant="outlined"
                                    label={t("Número de reserva")}
                                    value={numReserva}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <Box my={2} />
                                <CustomButton
                                    onClick={comprovar}
                                    title={t("Comprovar")}
                                />
                            </Box>
                        </Box>
                    </Container>
                </animated.div>
                <animated.div
                    style={{
                        opacity: props.opacityD,
                    }}
                >
                    <ReservaCheckin reserva={reserva} />
                </animated.div>
                <Element name="formulari">
                    <FormulariReserva
                        showContinuar={showContinuarCodi}
                        habitacio={reserva?.habitacio}
                        dateS={reserva?.dateS}
                        dateF={reserva?.dateF}
                        persones={reserva?.persones_val - 1}
                        preu={reserva?.preu}
                        loading={loading}
                        setLoading={setLoading}
                        checkinForm
                        unique_id={reserva?.unique_id}
                    />
                </Element>

                <Condicions showNits={false} />
            </Container>
        </Box>
    );
};

export default CheckIn;
