import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '15px 25px',
    borderRadius: 15,
    lineHeight: 1.5,
    color: 'white',
    backgroundColor: '#424242',
    borderColor: '#424242',
    fontFamily: 'Open Sans',
    '&:hover': {
        backgroundColor: 'white',
        color: '#424242',
        borderColor: '#424242',
        boxShadow: '10 10 10 0.2rem #424242',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#424242',
        borderColor: '#424242',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #424242',
    },
});

const BootstrapButtonSM = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '10px 20px',
    borderRadius: 15,
    lineHeight: 1.5,
    color: 'white',
    backgroundColor: '#424242',
    borderColor: '#424242',
    fontFamily: 'Open Sans',
    '&:hover': {
        backgroundColor: 'white',
        color: '#424242',
        borderColor: '#424242',
        boxShadow: '10 10 10 0.2rem #424242',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#424242',
        borderColor: '#424242',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #424242',
    },
});

export default function CustomButton({ title, sm, ...rest }) {
    return sm ? (
        <BootstrapButtonSM {...rest} variant="contained">
            {title}
        </BootstrapButtonSM>
    ) : (
        <BootstrapButton {...rest} variant="contained">
            {title}
        </BootstrapButton>
    );
}
