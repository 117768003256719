import { Cancel } from '@mui/icons-material';
import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Styles from '../Style';

const UrlKO = () => {
    const classes = Styles();
    return (
        <Box className={classes.root}>
            <Container maxWidth="lg" className={classes.main}>
                <Box style={{ color: 'red' }} className={classes.titol}>
                    <Cancel />
                    <Typography variant="h2">Pagament fallat</Typography>
                </Box>
                <Typography className={classes.titol}>
                    Alguna cosa no ha anat bé. Torna-ho a intentar més tard.
                    Disculpa les molèsties.
                </Typography>
            </Container>
        </Box>
    );
};

export default UrlKO;
