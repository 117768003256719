import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { changeEmail, reenviarCorreus } from "../../../../database/API";
import { useParams } from "react-router";
import { ForwardToInbox } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Edit } from "react-feather";

function ReenviarCorreus({ email, setUp }) {
    const [nouEmail, setNouEmail] = useState(email);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const { key } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const reenviar = async () => {
        setLoading(true);
        const { message } = await reenviarCorreus(key);
        enqueueSnackbar(message, {
            variant: "success",
        });
        setOpen(false);
        setLoading(false);
    };

    const editar = async () => {
        setLoading(true);
        const message = await changeEmail({ email: nouEmail }, key);
        enqueueSnackbar(message, {
            variant: "success",
        });
        setOpenEdit(false);
        setLoading(false);
        setUp((prev) => prev + 1);
    };

    return (
        <Box>
            <Tooltip title="Clica per a reenviar els correus">
                <Button onClick={() => setOpen(true)}>
                    <Typography
                        variant="caption"
                        display="flex"
                        jusifyContent="center"
                        alignItems={"center"}
                    >
                        {email} <ForwardToInbox />
                    </Typography>
                </Button>
            </Tooltip>
            <Tooltip title="Clica per editar el correu">
                <Button onClick={() => setOpenEdit(true)}>
                    <Typography
                        variant="caption"
                        display="flex"
                        jusifyContent="center"
                        alignItems={"center"}
                    >
                        <Edit />
                    </Typography>
                </Button>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Segur que vols reenviar els e-mails?
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Si ho fas, es tornaran a enviar els correu electrònics
                        tant al client ({email}) com al correu d'administració
                        (reserves@lacabanaberga.cat).
                    </Typography>
                    <Box my={3} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Tancar</Button>
                    <Button
                        autoFocus
                        onClick={reenviar}
                        variant="contained"
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={20} /> : "Reenviar"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"md"}
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    Editar el correu electrònic
                </DialogTitle>
                <DialogContent>
                    <TextField
                        onChange={(event) =>
                            setNouEmail(event.currentTarget.value)
                        }
                        value={nouEmail}
                        label="E-mail"
                        style={{ marginTop: 10 }}
                        fullWidth
                    />
                    <Box my={3} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEdit(false)}>Tancar</Button>
                    <Button
                        autoFocus
                        onClick={editar}
                        variant="contained"
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={20} /> : "Guardar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default ReenviarCorreus;
