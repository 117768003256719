import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Edit, Trash } from 'react-feather';
import { deleteElement } from '../database/API';
import ExtraEdit from './ExtraEdit';

const useStyles = makeStyles((theme) => ({
    item: { paddingTop: 25 },
    block: {
        boxShadow: '#00000020 1px 3px 20px 1px ',
        borderRadius: 30,
        zIndex: 0,
        backgroundColor: 'white',
        overflow: 'hidden',
        position: 'relative',
    },
}));

const ExtraElement = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { item, setUpdate } = props;
    const { enqueueSnackbar } = useSnackbar();

    const deleteExtra = async () => {
        const message = await deleteElement(item.id, 'extra');
        setUpdate((prev) => prev + 1);
        enqueueSnackbar(message, {
            variant: 'success',
        });
    };

    return (
        <Grid item md={4} xs={12} key={item.id}>
            <Box className={classes.block}>
                <CardHeader
                    className={classes.header}
                    action={
                        <>
                            <IconButton onClick={() => setOpen(true)}>
                                <Edit />
                            </IconButton>
                            <IconButton onClick={deleteExtra}>
                                <Trash />
                            </IconButton>
                        </>
                    }
                    title={item.nom}
                />
                <CardContent>
                    <Grid container justify="space-between">
                        <Grid item md={6}>
                            <Typography variant="body1" component="p">
                                {item.preu} €
                            </Typography>
                        </Grid>
                        <Grid item md={6} style={{ textAlign: 'right' }}>
                            <Typography variant="body2" component="p">
                                {item.descripcio && item.descripcio}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Box>
            <ExtraEdit
                item={item}
                openExtra={open}
                setOpenExtra={setOpen}
                setUpdate={setUpdate}
            />
        </Grid>
    );
};

export default ExtraElement;
