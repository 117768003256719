import { ArrowRightAlt } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React from "react";

function Dates({ reserva }) {
    const classes = useStyles();
    return (
        <Box className={classes.block}>
            <Box mb={3}>
                <Typography variant="h2">Dates</Typography>
            </Box>
            <Box className={classes.dates}>
                <Box p={2}>
                    <Typography variant="caption">Data entrada</Typography>
                    <Typography>
                        {moment(reserva?.dateS).format("LL")}
                    </Typography>
                </Box>
                <Box className={classes.icon}>
                    <ArrowRightAlt />
                </Box>

                <Box p={2}>
                    <Typography variant="caption">Data sortida</Typography>
                    <Typography>
                        {moment(reserva?.dateF).format("LL")}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default Dates;

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
    },
    dates: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));
