import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableContainer,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Download, Edit } from "react-feather";
import CrearFactura from "./CrearFactura";
import Dada from "./Dada";

function Factura({ reserva, downloadFactura, loading, setLoading, setUp }) {
    const classes = useStyles();
    const [openCrear, setOpenCrear] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Box className={classes.block}>
            <Grid container>
                <Grid item md={6}>
                    <Typography variant="h2">Facturació</Typography>
                </Grid>
                <Grid item md={6} textAlign="right">
                    <Tooltip title="Descarregar factura">
                        <Button onClick={downloadFactura}>
                            {loading ? (
                                <CircularProgress size={21} />
                            ) : (
                                <Download />
                            )}
                        </Button>
                    </Tooltip>
                    <Tooltip title="Modificar dades">
                        <Button onClick={() => setOpenCrear(true)}>
                            <Edit />
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        <Dada title="Raó Social" value={reserva?.rao} />
                        <Dada title="NIF" value={reserva?.nif} />
                        <Dada title="Adreça" value={reserva?.adreca} />
                        <Dada title="Població" value={reserva?.poblacio} />
                        <Dada
                            title="Codi postal"
                            value={reserva?.codi_postal}
                        />
                        <Dada title="País" value={reserva?.pais} />
                    </TableBody>
                </Table>
            </TableContainer>
            <CrearFactura
                setOpen={setOpenCrear}
                enqueueSnackbar={enqueueSnackbar}
                open={openCrear}
                setLoading={setLoading}
                setUp={setUp}
                reserva={reserva}
            />
        </Box>
    );
}

export default Factura;

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
    },
    dates: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));
