import { Box, Chip } from '@mui/material';

const ExtraChip = ({ item }) => {
    return (
        <Box my={2}>
            <Chip
                label={
                    item.nom +
                    ' x ' +
                    item.count +
                    ' + ' +
                    item.preu * item.count +
                    ' €'
                }
            ></Chip>
            <br />
        </Box>
    );
};

export default ExtraChip;
