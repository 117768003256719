import { Box, Container, Fade, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CheckCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Dates from '../../../components.js/Dates';
import { getReserva } from '../../../database/API';
import Styles from '../Style';

const UrlOK = () => {
    const classes = Styles();
    const { t, i18n } = useTranslation();

    const [reserva, setReserva] = useState();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    // useEffect(() => {
    //     console.log(id);
    //     const get = async () => {
    //         const { reserva } = await getReserva(id);
    //         setReserva(reserva);
    //         setLoading(false);
    //         console.log(reserva);
    //     };
    //     get();
    // }, [id, setLoading, setReserva]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Box className={classes.root}>
            <Container>
                <Box mt={4}>
                    <Typography variant="h1" className={classes.titol}>
                        {t('Moltes gràcies')}
                    </Typography>
                    <Box className={classes.titol}>
                        <CheckCircle />
                        <Typography variant="h2" style={{ color: 'green' }}>
                            {t('Compra efectuada amb èxit')}
                        </Typography>
                    </Box>
                    <Box textAlign="center" mt={2}>
                        <Typography variant="h3">
                            {t("Consulta l'E-mail per a més informació")}
                        </Typography>
                    </Box>
                </Box>

                {/* <Box className={classes.resum}>
                    <Typography variant="h3" style={{ textAlign: 'center' }}>
                        Resum de la reserva ({reserva?.unique_id})
                    </Typography>

                    <Box mt={3} />
                    {!loading && (
                        <Fade in={!loading}>
                            <div>
                                <Box className={classes.reserva}>
                                    <Box className={classes.date}>
                                        <Box mb={3}>
                                            <Typography variant="h3">
                                                Reserva
                                            </Typography>
                                        </Box>
                                        <Grid container spacing={3}>
                                            <Grid item md={3} xs={12}>
                                                <Box
                                                    className={classes.dates}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <Box p={2}>
                                                        <Typography variant="caption">
                                                            {
                                                                reserva
                                                                    .habitacio
                                                                    .persones
                                                            }{' '}
                                                            persones
                                                        </Typography>
                                                        <Typography>
                                                            {
                                                                reserva
                                                                    .habitacio
                                                                    .nom
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Dates
                                                    dateF={reserva.dateF}
                                                    dateS={reserva.dateS}
                                                    style={{ width: '100%' }}
                                                />
                                            </Grid>
                                            <Grid item md={3} xs={12}>
                                                <Box
                                                    className={classes.dates}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <Box p={2}>
                                                        <Typography variant="caption">
                                                            Preu
                                                        </Typography>
                                                        <Typography>
                                                            {reserva.preu}€
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                                <Box my={3} />
                                <Grid container spacing={3}>
                                    {reserva.persones?.map((item, index) => (
                                        <Grid item md={12}>
                                            <Box className={classes.block}>
                                                <Box p={4}>
                                                    <Typography variant="h3">
                                                        Persona {index + 1}
                                                    </Typography>
                                                    <Box my={3} />
                                                    <Grid container spacing={3}>
                                                        <Grid item md={4}>
                                                            <Typography variant="caption">
                                                                Nom
                                                            </Typography>
                                                            <Typography>
                                                                {item.nom}{' '}
                                                                {item.cognom1}{' '}
                                                                {item.cognom2}
                                                            </Typography>
                                                        </Grid>
                                                        {item.dni && (
                                                            <>
                                                                <Grid
                                                                    item
                                                                    md={4}
                                                                >
                                                                    <Typography variant="caption">
                                                                        E-mail
                                                                    </Typography>
                                                                    <Typography>
                                                                        {
                                                                            item.email
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={4}
                                                                >
                                                                    <Typography variant="caption">
                                                                        DNI
                                                                    </Typography>
                                                                    <Typography>
                                                                        {
                                                                            item.dni
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Box my={3} />
                                <Typography
                                    variant="h4"
                                    style={{ textAlign: 'center' }}
                                >
                                    Rebràs el codi d'entrada per correu
                                    electrònic
                                </Typography>
                            </div>
                        </Fade>
                    )}
                </Box> */}
            </Container>
        </Box>
    );
};

export default UrlOK;
