import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
    canviarHabitacio,
    comprovarDisponibilitat,
    get,
} from "../../../../database/API";

const CanviHabitacio = ({ open, setOpen, habitacio, reserva_id, setUp }) => {
    const [habitacions, setHabitacions] = useState([]);
    const [hab, setHab] = useState(habitacio);
    const [solapat, setSolapat] = useState(true);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const obtenir = async () => {
            const { resultat } = await get("habitacions");
            setHabitacions(resultat);
            setLoading(false);
        };
        obtenir();
    }, []);

    const canviar = async () => {
        setLoading(true);
        const missatge = await canviarHabitacio(reserva_id, hab);
        enqueueSnackbar(missatge, {
            variant: "success",
        });
        setUp((prev) => prev + 1);
        setOpen(false);
        setLoading(false);
    };

    const canviHabitacio = async (value) => {
        setLoading(true);
        setHab(value.target.value);
        const solapat = await comprovarDisponibilitat(
            reserva_id,
            value.target.value
        );
        setSolapat(solapat);
        setLoading(false);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Canvi d'habitació</DialogTitle>
            <DialogContent>
                <Typography>
                    Canvia l'habitació de la reserva. Això eliminarà
                    l'esdeveniment de Google Calendar i en crearà un de nou.
                </Typography>
                <Box my={3} />
                <FormControl fullWidth>
                    <InputLabel>Habitació</InputLabel>
                    <Select
                        label="Habitació"
                        value={hab ?? habitacio}
                        onChange={(value) => canviHabitacio(value)}
                        required
                    >
                        {habitacions?.map((item) => (
                            <MenuItem value={item.id}>{item.nom}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {solapat && (
                    <Typography variant="error">
                        Hi ha una reserva a aquesta habitació
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Tancar</Button>
                {loading ? (
                    <Button variant="contained" disabled>
                        <CircularProgress size={12} /> Canviar habitació
                    </Button>
                ) : (
                    <Button
                        onClick={() => canviar()}
                        variant="contained"
                        disabled={hab === habitacio || solapat}
                    >
                        Canviar habitació
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CanviHabitacio;
