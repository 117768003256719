import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@mui/material';
import { Input, Menu } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { logout } from '../../database/API';
import Logo from '../../assets/img/logo_petit_hotel.svg';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiToolbar-root': {
            boxShadow: '#00000020 1px 3px 20px 1px ',
            backgroundColor: 'white',
        },
    },
    logo: {
        height: '70px !important',
    },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    let navigate = useNavigate();

    const logOut = () => {
        logout();
        localStorage.removeItem('user');
        localStorage.removeItem('isLoggedIn');
        navigate('/');
    };

    return (
        <AppBar
            className={clsx(classes.root, className)}
            elevation={0}
            {...rest}
        >
            <Toolbar classes={classes.root}>
                <RouterLink to="/">
                    <img
                        src={Logo}
                        alt="Logo La Cabana"
                        className={classes.logo}
                        width={200}
                    />
                </RouterLink>
                <Box flexGrow={1} />
                <Hidden>
                    <IconButton color="primary" onClick={() => logOut()}>
                        <Input />
                    </IconButton>
                </Hidden>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={() => onMobileNavOpen()}
                    >
                        <Menu />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

export default TopBar;
