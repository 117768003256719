import { ArrowRightAlt } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '../views/public/Style';

const Dates = ({ dateS, dateF }) => {
    const classes = Styles();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        console.log(i18n.language);
    }, [i18n]);

    return (
        <Box className={classes.dates}>
            <Box p={2}>
                <Typography variant="caption">{t('Data entrada')}</Typography>
                <Typography>
                    {dateS
                        ? moment(dateS).locale(i18n.language).format('LL')
                        : t('Selecciona data')}
                </Typography>
            </Box>
            <Box className={classes.icon}>
                <ArrowRightAlt />
            </Box>

            <Box p={2}>
                <Typography variant="caption">{t('Data sortida')}</Typography>
                <Typography>
                    {dateF
                        ? moment(dateF).locale(i18n.language).format('LL')
                        : t('Selecciona data')}
                </Typography>
            </Box>
        </Box>
    );
};

export default Dates;
