import { Add } from "@mui/icons-material";
import { Box, CircularProgress, Container, Fade, IconButton, Tooltip, Grid, Typography, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import CustomButton from "../../components.js/CustomButton";
import ExtraEdit from "../../components.js/ExtraEdit";
import ExtraElement from "../../components.js/ExtraElement";
import HabitacioEdit from "../../components.js/HabitacioEdit";
import HabitacioElement from "../../components.js/HabitacioElement";
import ParamField from "../../components.js/ParamField";
import Title from "../../components.js/Title";
import XecEdit from "../../components.js/XecEdit";
import XecElement from "../../components.js/XecElement";
import { get, updateParams, updatePreus } from "../../database/API";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 80,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },
}));

const HabitacionsView = () => {
    const classes = useStyles();
    const [habitacions, setHabitacions] = useState();
    const [preus, setPreus] = useState();
    const [extras, setExtras] = useState();
    const [params, setParams] = useState();
    const [xecs, setXecs] = useState();
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(0);
    const [open, setOpen] = useState(false);
    const [openExtra, setOpenExtra] = useState(false);
    const [openXec, setOpenXec] = useState(false);
    const formPreus = useForm();
    const formParams = useForm();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const obtenir = async () => {
            const habitacions = await get("habitacions");
            const preus = await get("preus");
            const params = await get("params");
            const extras = await get("extras");
            const xecs = await get("xecs");

            setHabitacions(habitacions.resultat);
            setPreus(preus.resultat);
            setXecs(xecs.resultat);
            setParams(params.resultat);
            setExtras(extras.resultat);
            setLoading(false);
        };

        obtenir();
    }, [update]);

    const enviar = async (values) => {
        const message = await updatePreus(values);
        enqueueSnackbar(message, {
            variant: "success",
        });
    };

    const enviarParams = async (values) => {
        const message = await updateParams(values);
        enqueueSnackbar(message, {
            variant: "success",
        });
    };

    return (
        <Container maxWidth={false} className={classes.container}>
            <Title title={"Preferències"} subtitle={"Administra les habitacions, els preus i paràmetres"} />
            <Box my={6} />
            <Box className={classes.block}>
                <Title
                    title={"Habitacions"}
                    button={
                        <Box>
                            <span style={{ marginLeft: 20 }}>
                                <Tooltip title="Crear habitació">
                                    <IconButton className={classes.addButton} onClick={() => setOpen(true)}>
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        </Box>
                    }
                />
                <Box pt={2} spacing={3}>
                    {!loading ? (
                        <Fade in={!loading}>
                            <Grid container spacing={4}>
                                {habitacions?.map((item) => {
                                    return <HabitacioElement item={item} setUpdate={setUpdate} />;
                                })}
                            </Grid>
                        </Fade>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Box>
            <Box className={classes.block}>
                <Title
                    title={"Extres"}
                    button={
                        <Box>
                            <span style={{ marginLeft: 20 }}>
                                <Tooltip title="Crear extra">
                                    <IconButton className={classes.addButton} onClick={() => setOpenExtra(true)}>
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        </Box>
                    }
                />
                <Box pt={2} spacing={3}>
                    {!loading ? (
                        <Fade in={!loading}>
                            <Grid container spacing={4}>
                                {extras?.map((item) => {
                                    return <ExtraElement item={item} setUpdate={setUpdate} />;
                                })}
                            </Grid>
                        </Fade>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Box>
            <Box className={classes.block}>
                <Title
                    title={"Xecs Regal"}
                    button={
                        <Box>
                            <span style={{ marginLeft: 20 }}>
                                <Tooltip title="Crear xec regal">
                                    <IconButton className={classes.addButton} onClick={() => setOpenXec(true)}>
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        </Box>
                    }
                />
                <Box pt={2} spacing={3}>
                    {!loading ? (
                        <Fade in={!loading}>
                            <Grid container spacing={4}>
                                {xecs?.map((item) => {
                                    return <XecElement item={item} setUpdate={setUpdate} />;
                                })}
                            </Grid>
                        </Fade>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Box>
            <Box className={classes.block}>
                <Title title={"Preus"} />
                <Box pt={2} spacing={3}>
                    {!loading ? (
                        <Fade in={!loading}>
                            <form onSubmit={formPreus.handleSubmit(enviar)}>
                                <Grid container spacing={4}>
                                    {preus?.map((item) => {
                                        return (
                                            <Grid item md={4}>
                                                <TextField
                                                    key={item.id}
                                                    fullWidth
                                                    label={item.nom + " - " + item?.habitacio?.nom}
                                                    name={String(item.id)}
                                                    type="text"
                                                    variant="outlined"
                                                    {...formPreus.register(String(item.id), {
                                                        value: item.preu,
                                                        valueAsNumber: true,
                                                    })}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                <Box my={4} />
                                <CustomButton type="submit" title={"Guardar"} />
                            </form>
                        </Fade>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Box>
            <Box className={classes.block}>
                <Title title={"Paràmetres"} />
                <Box pt={2} spacing={3}>
                    {!loading ? (
                        <Fade in={!loading}>
                            <form onSubmit={formParams.handleSubmit(enviarParams)}>
                                <Grid container spacing={4}>
                                    {params?.map((item) => {
                                        return (
                                            <Grid item md={4}>
                                                <ParamField item={item} form={formParams} />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                <Box my={4} />
                                <CustomButton type="submit" title={"Guardar"} />
                            </form>
                        </Fade>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Box>
            <HabitacioEdit open={open} setOpen={setOpen} setUpdate={setUpdate} />
            <ExtraEdit openExtra={openExtra} setOpen={setOpen} setUpdate={setUpdate} setOpenExtra={setOpenExtra} />
            <XecEdit openXec={openXec} setOpen={setOpen} setUpdate={setUpdate} setOpenXec={setOpenXec} />
        </Container>
    );
};

export default HabitacionsView;
