import React, { useEffect, useState } from "react";
import { animated } from "@react-spring/web";
import Styles from "../views/public/Style";
import { Grid, Typography, Box } from "@mui/material";
import Dates from "./Dates";
import { useTranslation } from "react-i18next";
import { obtenirPreus } from "../database/API";

export default function ReservaCheckin({ reserva }) {
    const classes = Styles();
    const { t } = useTranslation();
    const [preuFinal, setPreuFinal] = useState(0);

    useEffect(() => {
        const obtenir = async () => {
            const { preu_final } = await obtenirPreus(reserva?.unique_id);
            setPreuFinal(preu_final);
        };
        if (reserva?.unique_id) obtenir();
    });

    return (
        <Box className={classes.reserva}>
            <Box mb={3}>
                <Typography variant="h3">
                    {t("Reserva")} {reserva?.unique_id}
                </Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <Box
                        className={classes.dates}
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <Box p={2}>
                            <Typography variant="caption">
                                {reserva?.persones_val} {t("persones")}
                            </Typography>
                            <Typography>{reserva?.habitacio?.nom}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Dates
                        dateF={reserva?.dateF}
                        dateS={reserva?.dateS}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box
                        className={classes.dates}
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <Box p={2}>
                            <Typography variant="caption">
                                {t("Preu")}
                            </Typography>
                            <Typography>{preuFinal} €</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
