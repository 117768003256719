import {
    Box,
    Button,
    CircularProgress,
    Container,
    Fade,
    Grid,
    Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import '../../theme/style.css';
import { get } from '../../database/API';
import 'moment/locale/ca';
import Styles from './Style';
import XecSelect from '../../components.js/XecSelect';
import ComComprar from '../../components.js/ComComprar';
import { animated, useSpring } from '@react-spring/web';
import FormCompraXec from '../../components.js/FormCompraXec';
import CustomButton from '../../components.js/CustomButton';
import { useTranslation } from 'react-i18next';

const CompraXec = () => {
    const classes = Styles();
    const [loading, setLoading] = useState(true);
    const [xecs, setXecs] = useState();
    const [xec, setXec] = useState('');
    const [showContinuar, setShowContinuar] = useState(false);
    const refInfo = useRef();
    const refXecs = useRef();
    const [heightDates, setHeightDates] = useState(
        refXecs?.current?.clientHeight
    );
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setLoading(true);
        const obtenir = async () => {
            const xecs = await get('xecs');
            setXecs(xecs.resultat);
            setLoading(false);
            setTimeout(() => {
                setHeightDates(refXecs.current.clientHeight);
            }, 500);
        };
        obtenir();
    }, []);

    useEffect(() => {
        setShowContinuar(xec !== '');
    }, [xec]);

    useEffect(() => {
        setTimeout(() => {
            setHeightDates(refXecs.current.clientHeight);
        }, 500);
    }, []);

    const props = useSpring({
        from: {
            opacity: 0,
            opacityD: 1,
            height: heightDates,
            heightD: refInfo?.current?.clientHeight,
        },
        opacity: showContinuar ? 0 : 1,
        height: showContinuar ? 0 : heightDates,
        opacityD: showContinuar ? 1 : 0,
        heightD: showContinuar ? refInfo?.current?.clientHeight : 0,
    });

    const eliminarXec = () => {
        setShowContinuar(false);
        setTimeout(() => {
            setXec('');
        }, 500);
    };

    return (
        <Box className={classes.root} style={{ overflow: 'hidden' }}>
            <Fade in={loading}>
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            </Fade>

            <ComComprar />
            <Box my={8} />
            <animated.div
                style={{
                    height: props.height,
                    opacity: props.opacity,
                }}
            >
                <Container ref={refXecs}>
                    <Grid container spacing={3}>
                        {xecs?.map((item) => (
                            <Grid item md={4} flex={1} display="flex">
                                <XecSelect item={item} setXec={setXec} />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </animated.div>

            <animated.div
                style={{
                    height: props.heightD,
                    opacity: props.opacityD,
                }}
            >
                <Container ref={refInfo}>
                    <Box display="flex" justifyContent="center" mb={5}>
                        <CustomButton
                            title={t('Canvia de xec')}
                            onClick={eliminarXec}
                        />
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <FormCompraXec
                                xec_id={xec?.id}
                                setLoading={setLoading}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <XecSelect item={xec} />
                        </Grid>
                    </Grid>
                </Container>
            </animated.div>
        </Box>
    );
};

export default CompraXec;
