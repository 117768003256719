import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useState } from "react";
import parse from "html-react-parser";
import { useForm } from "react-hook-form";
import { devolucio } from "../../../../database/API";
import { useParams } from "react-router";

function Devolucio({ reserva, setLoading }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState();
    const { key } = useParams();

    const formDevolucio = useForm({
        reValidateMode: "onChange",
        defaultValues: { tantpercent: 1 },
    });

    const retornar = async (values) => {
        setLoading(true);
        const { resultat } = await devolucio(values, key);
        setForm(resultat);
        setTimeout(() => document.forms["redsys_form"].submit(), 500);
    };

    return (
        <Box className={classes.block}>
            <Typography variant="h2">Devolució</Typography>
            <Box m={3}></Box>
            {parse(form ? form.data : "")}
            <Box my={3}>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => setOpen(true)}
                    color="danger"
                    style={{ zIndex: 0 }}
                    disabled={reserva?.state_id === 4 || reserva?.state_id === 6}
                >
                    Devolució
                </Button>
            </Box>
            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <form onSubmit={formDevolucio.handleSubmit(retornar)}>
                    <DialogTitle id="alert-dialog-title">Segur que vols fer la devolució de la reserva?</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Si ho fas, es retornarà el % de l'import que determinis al client, s'eliminarà la reserva del calendari de Google i es posarà la
                            reserva com a estat "Cancel·lat".
                        </Typography>
                        <Box my={3} />
                        <Typography>Les dades del client i la reserva es conservaràn.</Typography>
                        <Box my={3} />
                        <FormControl fullWidth>
                            <InputLabel id={"tantpercent"}>% a retornar</InputLabel>
                            <Select labelId="tantpercent" id="tantpercent" label=" % a retornar" {...formDevolucio.register("tantpercent")} required>
                                <MenuItem value={1}>100%</MenuItem>
                                <MenuItem value={0.75}>75%</MenuItem>
                                <MenuItem value={0.5}>50%</MenuItem>
                                <MenuItem value={0.25}>25%</MenuItem>
                                <MenuItem value={0}>0%</MenuItem>
                            </Select>
                        </FormControl>
                        <Box my={3}>
                            <Typography variant="body1">{formDevolucio.watch("tantpercent") * reserva?.preu} € de retorn</Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Tancar</Button>
                        <Button autoFocus type="submit" variant="contained" color="danger">
                            Retornar import
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
}

export default Devolucio;

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
    },
    dates: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));
