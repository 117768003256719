import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { changeState } from "../../../../database/API";

function CanviEstat({ reserva, setUp, states }) {
    const classes = useStyles();
    const { key } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const formState = useForm({
        reValidateMode: "onChange",
        defaultValues: { state_id: 0 },
    });

    useEffect(() => {
        formState.reset({ state_id: reserva?.state_id });
    }, [formState, key, reserva?.state_id]);

    const change = async (values) => {
        console.log(key);
        const message = await changeState(values, key);
        setUp((prev) => prev + 1);
        enqueueSnackbar(message, {
            variant: "success",
        });
    };

    return (
        <Box className={classes.block}>
            <Typography variant="h2">Canvi d'estat</Typography>
            <Box m={3}></Box>
            <form onSubmit={formState.handleSubmit(change)}>
                <FormControl fullWidth>
                    <InputLabel id={"state_id"}>Nou estat</InputLabel>
                    <Select
                        labelId="state_id"
                        id="state_id"
                        label="Nou estat"
                        {...formState.register("state_id")}
                    >
                        {states?.map((item) => {
                            return (
                                <MenuItem value={item.id}>{item.nom}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Box my={3}>
                    <Button
                        variant="contained"
                        fullWidth
                        type="submit"
                        style={{ zIndex: 0 }}
                    >
                        Canviar
                    </Button>
                </Box>
            </form>
        </Box>
    );
}

export default CanviEstat;

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
    },
    dates: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));
