import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Box, CircularProgress, createTheme, Fade } from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import Columns from "./PersonesColumns";
import PersonesOptions from "./PersonesOptions";
import { getAdmin, getPaisos } from "../../../../database/API";
import Title from "../../../../components.js/Title";
import PersonaEdit from "../../../../components.js/PersonaEdit";

makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 80,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },
}));

const PersonesTable = () => {
    const [persones, setPersones] = useState();
    const [loading, setLoading] = useState(true);
    const options = PersonesOptions(persones);
    const [openEdit, setOpenEdit] = useState(false);
    const [persona, setPersona] = useState("");
    const [paisos, setPaisos] = useState([]);
    const [up, setUp] = useState(0);
    const columns = Columns(persones, setPersona, setOpenEdit);

    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            boxShadow: "#00000020 1px 3px 20px 1px ",
                            borderRadius: 30,
                            padding: 30,
                            zIndex: 0,
                            marginBottom: 80,
                            backgroundColor: "white",
                            overflow: "hidden",
                            position: "relative",
                        },
                    },
                },
            },
        });

    useEffect(() => {
        const get = async () => {
            const { resultat } = await getAdmin("persones");
            setPersones(resultat);
            setLoading(false);
        };

        get();
    }, [up]);

    useEffect(() => {
        const obtenir = async () => {
            const { paisos } = await getPaisos();
            const paisosNoms = paisos.map((item) => {
                return { label: item.translations.spa.common };
            });
            setPaisos(paisosNoms.sort((a, b) => (a.label > b.label ? 1 : -1)));
        };
        obtenir();
    }, []);

    return (
        <>
            <Title title="Clients" />
            <Box pt={2} spacing={3}>
                {!loading ? (
                    <Fade in={!loading}>
                        <div>
                            <ThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    data={persones}
                                    columns={columns}
                                    options={options}
                                />
                            </ThemeProvider>
                        </div>
                    </Fade>
                ) : (
                    <CircularProgress />
                )}
                <PersonaEdit
                    open={openEdit}
                    setOpen={setOpenEdit}
                    persona={persona}
                    paisos={paisos}
                    setUp={setUp}
                />
            </Box>
        </>
    );
};

export default PersonesTable;
