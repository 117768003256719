import { CircleSharp, WheelchairPickupRounded } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Circle, Edit, User } from "react-feather";
import { useNavigate } from "react-router";
import HabitacioEdit from "./HabitacioEdit";

const useStyles = makeStyles((theme) => ({
    item: { paddingTop: 25 },
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        zIndex: 0,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },
}));

const HabitacioElement = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { item, setUpdate } = props;

    return (
        <Grid item md={4} xs={12} key={item.id}>
            <Box className={classes.block}>
                <CardHeader
                    className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label="Editar flor"
                                onClick={() => setOpen(true)}
                            >
                                <Edit />
                            </IconButton>
                        </>
                    }
                    title={
                        <>
                            <CircleSharp
                                style={{ fill: item.color, marginRight: 10 }}
                            />
                            {item.nom}
                        </>
                    }
                />
                <CardContent>
                    <Grid container justify="space-between">
                        <Grid item md={6}>
                            <Typography variant="body2" component="p">
                                <User /> {item.min_persones}-{item.persones}{" "}
                                persones
                            </Typography>
                        </Grid>
                        <Grid item md={6} style={{ textAlign: "right" }}>
                            <Typography variant="body2" component="p">
                                {item.discapacitats ? (
                                    <WheelchairPickupRounded />
                                ) : (
                                    ""
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Box>
            <HabitacioEdit
                item={item}
                open={open}
                setOpen={setOpen}
                setUpdate={setUpdate}
            />
        </Grid>
    );
};

export default HabitacioElement;
