import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import { getAdmin } from "../../../../database/API";
import {
    Box,
    Button,
    CircularProgress,
    createTheme,
    Fade,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import Columns from "./ReservesColumns";
import OrdersOptions from "./ReservesOptions";
import Title from "../../../../components.js/Title";
import { Add, Refresh } from "@mui/icons-material";
import { useNavigate } from "react-router";

makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 80,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },
}));

const ReservesTable = (props) => {
    const { id } = props;
    const [reserves, setReserves] = useState();
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const columns = Columns(reserves);
    const options = OrdersOptions(reserves);
    const navigate = useNavigate();

    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            boxShadow: "#00000020 1px 3px 20px 1px ",
                            borderRadius: 30,
                            padding: 30,
                            zIndex: 0,
                            marginBottom: 80,
                            backgroundColor: "white",
                            overflow: "hidden",
                            position: "relative",
                        },
                    },
                },
            },
        });

    useEffect(() => {
        const get = async () => {
            const { resultat } = await getAdmin("reserves");
            setReserves(resultat);
            setLoading(false);
        };

        get();
    }, [id]);

    const refresca = () => {
        const llargada = reserves?.length;
        setLoading(true);
        const get = async () => {
            const results = await getAdmin("reserves");
            setReserves(results.resultat);
            setLoading(false);
            const llargada_nova = results.resultat?.length;
            if (llargada_nova - llargada === 0) {
                enqueueSnackbar("No hi ha noves reserves", {
                    variant: "success",
                });
            } else {
                enqueueSnackbar(
                    "S'ha afegit " + (llargada_nova - llargada) + " reserva/es",
                    {
                        variant: "success",
                    }
                );
            }
        };
        get();
    };

    return (
        <>
            <Title
                title="Reserves"
                button={
                    <Box>
                        <Tooltip title="Refrescar">
                            <IconButton onClick={() => refresca()}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                        <Button
                            onClick={() => navigate("/admin/add")}
                            variant="contained"
                        >
                            <Add /> <Typography>Afegir reserva</Typography>
                        </Button>
                    </Box>
                }
            />
            <Box pt={2} spacing={3}>
                {!loading ? (
                    <Fade in={!loading}>
                        <div>
                            <ThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    data={reserves}
                                    columns={columns}
                                    options={options}
                                />
                            </ThemeProvider>
                        </div>
                    </Fade>
                ) : (
                    <CircularProgress />
                )}
            </Box>
        </>
    );
};

export default ReservesTable;
