import { Box, Container, Fade, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import Title from "../../../components.js/Title";
import Loading from "../../../components.js/Loading";
import { getAdmin } from "../../../database/API";
import ReactDOM from "react-dom";
import { DialogAddDia } from "./DialogAddDia";
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 80,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },
    calendar: {
        fontFamily: "Open Sans",
        color: theme.palette.text.primary,
        marginBottom: 100,
        "& .fc-button-primary": {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            "&:hover": {
                backgroundColor: theme.palette.primary.hover,
                borderColor: theme.palette.primary.hover,
            },
        },
        "& .fc-scroller-harness-liquid": {
            height: "100%",
        },
    },
}));

const ConfigCalendari = () => {
    const classes = useStyles();
    const [reserves, setReserves] = useState([]);
    const [loading, setLoading] = useState(true);
    const [preus, setPreus] = useState([]);
    const [dies, setDies] = useState([]);
    const [open, setOpen] = useState(false);
    const [dia, setDia] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { resultat } = await getAdmin("preus");
            setPreus(resultat);
            const dies = await getAdmin("dies");
            setDies(dies.resultat);
            setLoading(false);
        };

        get();
    }, []);

    const obrirDia = (date) => {
        setOpen(true);
        setDia(date);
    };

    return (
        <Container maxWidth={false} className={classes.container}>
            <Box>
                <Box pt={2} spacing={3}>
                    <Title title="Calendari" />
                    <Box my={4} />
                    <Box>
                        <Box my={3} />
                        {!loading && (
                            <div className={classes.calendar}>
                                <FullCalendar
                                    plugins={[interactionPlugin, dayGridPlugin]}
                                    initialView="dayGridMonth"
                                    locale={"ca"}
                                    firstDay={1}
                                    buttonText={{ today: "avui" }}
                                    dateClick={({ date }) => obrirDia(date)}
                                    dayCellDidMount={({ el, date }) => renderDia(el, date, dies, preus)}
                                />
                            </div>
                        )}
                    </Box>
                </Box>
            </Box>
            <DialogAddDia open={open} setOpen={setOpen} dia={dia} preus={preus} setDies={setDies} setLoading={setLoading} dies={dies} />
        </Container>
    );
};

export default ConfigCalendari;

const renderDia = (el, date, dies, preus) => {
    console.log("JKLS");
    const trobada = dies?.find((dia) => moment(dia.dia).format("YYYY-DD-MM") === moment(date).format("YYYY-DD-MM"));
    let preu_torre = preus?.find((preu) => preu.persones === 1 && preu.habitacio_id === 1).preu;
    let preu_montserrat = preus?.find((preu) => preu.persones === 1 && preu.habitacio_id === 2).preu;
    let preu_queralt = preus?.find((preu) => preu.persones === 1 && preu.habitacio_id === 3).preu;

    let classTrobada1 = "";
    let classTrobada2 = "";
    let classTrobada3 = "";

    var newContent1 = "";
    var newContent2 = "";
    var newContent3 = "";

    var newContentdos = "";

    if (trobada?.tantpercent_torre) {
        preu_torre = Math.round(preu_torre * (trobada.tantpercent_torre ? trobada.tantpercent_torre / 100 + 1 : 1) * 100) / 100;
        newContent1 = document.createTextNode(preu_torre + "€ (" + (trobada.tantpercent_torre ?? 0) + "%)");
        classTrobada1 = trobada?.tantpercent_torre > 0 ? " trobat" : " trobat-pos";
    } else {
        newContent1 = document.createTextNode(preu_torre + "€");
    }

    if (trobada?.tantpercent_montserrat) {
        preu_montserrat = Math.round(preu_montserrat * (trobada.tantpercent_montserrat ? trobada.tantpercent_montserrat / 100 + 1 : 1) * 100) / 100;
        newContent2 = document.createTextNode(preu_montserrat + "€ (" + (trobada.tantpercent_montserrat ?? 0) + "%)");
        classTrobada2 = trobada?.tantpercent_montserrat > 0 ? " trobat" : " trobat-pos";
    } else {
        newContent2 = document.createTextNode(preu_montserrat + "€");
    }

    if (trobada?.tantpercent_queralt) {
        preu_queralt = Math.round(preu_queralt * (trobada.tantpercent_queralt ? trobada.tantpercent_queralt / 100 + 1 : 1) * 100) / 100;
        newContent3 = document.createTextNode(preu_queralt + "€ (" + (trobada.tantpercent_queralt ?? 0) + "%)");
        classTrobada3 = trobada?.tantpercent_queralt > 0 ? " trobat" : " trobat-pos";
    } else {
        newContent3 = document.createTextNode(preu_queralt + "€");
    }

    const div1 = document.createElement("div");
    div1.className = "habitacio_cal hab_torre" + classTrobada1;
    div1.appendChild(newContent1);

    const div2 = document.createElement("div");
    div2.className = "habitacio_cal hab_montserrat" + classTrobada2;
    div2.appendChild(newContent2);

    const div3 = document.createElement("div");
    div3.className = "habitacio_cal hab_queralt" + classTrobada3;
    div3.appendChild(newContent3);

    el.getElementsByClassName("fc-scrollgrid-sync-inner")[0].getElementsByClassName("fc-daygrid-day-events")[0].append(div1);
    el.getElementsByClassName("fc-scrollgrid-sync-inner")[0].getElementsByClassName("fc-daygrid-day-events")[0].append(div2);
    el.getElementsByClassName("fc-scrollgrid-sync-inner")[0].getElementsByClassName("fc-daygrid-day-events")[0].append(div3);

    if (trobada?.dosnits) {
        newContentdos = document.createTextNode("Mínim dues nits");
        const divdos = document.createElement("div");
        divdos.className = "habitacio_cal dosnits";
        divdos.appendChild(newContentdos);
        el.getElementsByClassName("fc-scrollgrid-sync-inner")[0].getElementsByClassName("fc-daygrid-day-events")[0].append(divdos);
    }
};
