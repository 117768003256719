import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { createXec, updateXec } from '../database/API';
import { useSnackbar } from 'notistack';

const XecEdit = ({ item, openXec, setOpenXec, setUpdate }) => {
    const { enqueueSnackbar } = useSnackbar();

    const guardar = async (values) => {
        let message = '';
        if (item) {
            message = await updateXec(values, item.id);
        } else {
            message = await createXec(values);
        }
        enqueueSnackbar(message, {
            variant: 'success',
        });
        setUpdate((prev) => prev + 1);
        setOpenXec(false);
    };
    return (
        <Dialog
            open={openXec}
            onClose={() => setOpenXec(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'lg'}
        >
            <DialogTitle id="alert-dialog-title">
                {item ? 'Editar' : 'Crear'} {item?.nom}
            </DialogTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    nom: item?.nom,
                    nom_es: item?.nom_es,
                    nom_en: item?.nom_en,
                    preu: item?.preu,
                    nits: item?.nits,
                    descripcio: item?.descripcio,
                    descripcio_es: item?.descripcio_es,
                    descripcio_en: item?.descripcio_en,
                }}
                validationSchema={Yup.object().shape({
                    nom: Yup.string().max(255).required('El nom és obligatòri'),
                    preu: Yup.number().required('El preu és obligatori'),
                    nits: Yup.number().required('Les nits son obligatòries'),
                    descripcio: Yup.string().required(
                        'La descripció és obligatòria'
                    ),
                })}
                onSubmit={(values, actions) => {
                    guardar(values, actions);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    resetForm,
                    isSubmitting,
                    setFieldValue,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.nom && errors.nom
                                        )}
                                        fullWidth
                                        helperText={touched.nom && errors.nom}
                                        label={'Nom'}
                                        margin="normal"
                                        name="nom"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.nom}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.nom_es && errors.nom_es
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.nom_es && errors.nom_es
                                        }
                                        label={'Nom castellà'}
                                        margin="normal"
                                        name="nom_es"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.nom_es}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.nom_en && errors.nom_en
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.nom_en && errors.nom_en
                                        }
                                        label={'Nom anglès'}
                                        margin="normal"
                                        name="nom_en"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.nom_en}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        error={Boolean(
                                            touched.preu && errors.preu
                                        )}
                                        fullWidth
                                        helperText={touched.preu && errors.preu}
                                        label={'Preu'}
                                        margin="normal"
                                        name="preu"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.preu}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        error={Boolean(
                                            touched.nits && errors.nits
                                        )}
                                        fullWidth
                                        helperText={touched.nits && errors.nits}
                                        label={'Nits'}
                                        margin="normal"
                                        name="nits"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.nits}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.descripcio &&
                                                errors.descripcio
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.descripcio &&
                                            errors.descripcio
                                        }
                                        label={'Descripció'}
                                        multiline
                                        margin="normal"
                                        name="descripcio"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.descripcio}
                                        variant="outlined"
                                        rows={8}
                                    />
                                </Grid>

                                <Grid item md={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.descripcio_es &&
                                                errors.descripcio_es
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.descripcio_es &&
                                            errors.descripcio_es
                                        }
                                        label={'Descripció castellà'}
                                        multiline
                                        margin="normal"
                                        name="descripcio_es"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.descripcio_es}
                                        variant="outlined"
                                        rows={8}
                                    />
                                </Grid>

                                <Grid item md={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.descripcio_en &&
                                                errors.descripcio_en
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.descripcio_en &&
                                            errors.descripcio_en
                                        }
                                        label={'Descripció anglès'}
                                        multiline
                                        margin="normal"
                                        name="descripcio_en"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.descripcio_en}
                                        variant="outlined"
                                        rows={8}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenXec(false)}>
                                Tancar
                            </Button>
                            <Button type="submit" autoFocus>
                                Guardar
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default XecEdit;
