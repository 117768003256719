import {
    Box,
    Checkbox,
    CircularProgress,
    Collapse,
    Container,
    Fade,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { ca } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReactDatePicker from "react-datepicker";
import "../../../theme/style.css";
import { addDays, parseISO } from "date-fns";
import { calendaris, calendarisBooking, ferReserva, get, getAdmin } from "../../../database/API";
import "moment/locale/ca";
import { WheelchairPickupRounded } from "@mui/icons-material";
import CustomButton from "../../../components.js/CustomButton";
import FormulariReserva from "../../../components.js/FormulariReserva";
import Dates from "../../../components.js/Dates";
import { makeStyles } from "@mui/styles";
import Title from "../../../components.js/Title";
import ExtraSmall from "../../../components.js/ExtraSmall";
import FormFactura from "./TableReserves/FormFactura";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { datesBetween, datesBetween2, datesBetweenInclosos, mergeIntervals } from "../../../utils/Utils";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
        paddingBottom: 80,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 80,
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
    },

    loading: {
        position: "fixed",
        zIndex: 10,
        height: "100%",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        display: "flex",
        width: "100%",
        backgroundColor: "#ffffff90",
    },

    titol: {
        color: "green",
        display: "flex",
        justifyContent: "center",
    },
    resum: {
        paddingTop: 40,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    datesCurt: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        left: 0,
        right: 0,
        paddingRight: 30,
        paddingLeft: 30,
    },
}));

const ReservaAdd = () => {
    const classes = useStyles();
    const [dateS, setDateS] = useState();
    const [dateF, setDateF] = useState();
    const [preu, setPreu] = useState(0);
    const [preuE, setPreuE] = useState(0);
    const [showNits, setShowNits] = useState(false);
    const [persones, setPersones] = useState("");
    const [personesRender, setPersonesRender] = useState();
    const [exclude, setExclude] = useState([]);
    const [showContinuar, setShowContinuar] = useState(false);
    const [loading, setLoading] = useState(true);
    const [habitacio, setHabitacio] = useState();
    const [habitacions, setHabitacions] = useState();
    const [preus, setPreus] = useState();
    const [params, setParams] = useState();
    const [extras, setExtras] = useState();
    const [selExtras, setSelExtras] = useState();
    const [states, setStates] = useState();
    const [state, setState] = useState(2);
    const [nomContacte, setNomContacte] = useState("");
    const [emailContacte, setEmailContacte] = useState("");
    const [phoneContacte, setPhoneContacte] = useState("");
    const [booking, setBooking] = useState(false);
    const [calendarisB, setCalendarisB] = useState([]);
    const [reservaBooking, setReservaBooking] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [intervals, setIntervals] = useState([]);

    // Factura
    const [factura, setFactura] = useState({
        rao: "",
        nif: "",
        adreca: "",
        poblacio: "",
        codi_postal: "",
        pais: "",
    });

    useEffect(() => {
        setLoading(true);
        const obtenir = async () => {
            const habitacions = await get("habitacions");
            const preus = await get("preus");
            const params = await get("params");
            const extras = await get("extras");
            const states = await get("states");

            setExtras(extras.resultat);
            setStates(states.resultat);
            setHabitacions(habitacions.resultat);
            setPreus(preus.resultat);
            setParams(params.resultat);
            setLoading(false);
        };
        obtenir();
    }, []);

    useEffect(() => {
        let ex = extras?.map((item) => {
            return { ...item, checked: false, count: 1 };
        });
        setSelExtras(ex);
    }, [extras]);

    useEffect(() => {
        let pr = 0;
        selExtras?.forEach((item) => {
            if (item.checked) pr = pr + item.preu * item.count;
        });
        setPreuE(pr);
    }, [selExtras]);

    useEffect(() => {
        setShowNits(params?.filter((item) => item.sku === "nits-reserva")?.[0]?.value === "1");
    }, [params]);

    useEffect(() => {
        setLoading(true);
        setPersones("");

        const getCalendars = async () => {
            const result = await calendaris(habitacio?.calendari);

            const result_booking = await calendarisBooking(habitacio?.id);

            const result_int = result.map((item) => {
                return { start: item?.start?.date, end: item?.end?.date };
            });

            const result_booking_int = result_booking.map((item) => {
                return {
                    start: moment(item.startDate, "YYYYMMDD").format("YYYY-MM-DD"),
                    end: moment(item.endDate, "YYYYMMDD").add(1, "days").format("YYYY-MM-DD"),
                };
            });

            const resultats = [...result_int, ...result_booking_int];

            if (resultats.length > 0) {
                const interval = resultats.map((i) => [moment(i.start).add(1, "day").format("YYYY-MM-DD"), i.end]);

                const result = mergeIntervals(interval);
                setIntervals(result);

                let ex = [];
                result.forEach((item) => {
                    if (item[0]) {
                        ex = [...ex, ...datesBetween2(item[0], item[1])];
                    }
                });

                setExclude(ex);
            }

            var rows = [];
            for (var i = habitacio?.min_persones - 1; i < habitacio?.persones; i++) {
                if (!(habitacio.discapacitats && i === 0)) {
                    rows.push(
                        <MenuItem value={i}>
                            {i + 1} {i === 0 ? "persona" : "persones"}
                        </MenuItem>
                    );
                }
            }
            setPersonesRender(rows);
            setTimeout(() => {
                setLoading(false);
            }, 300);
        };
        habitacio && getCalendars();
    }, [habitacio]);

    useEffect(() => {
        const datesOcupades = datesBetween(dateS, dateF);

        const preu = preus?.filter((i) => i.persones === persones + 1 && i.habitacio_id === habitacio.id)?.[0]?.preu;
        setPreu(datesOcupades.length === 0 ? preu : datesOcupades.length * preu);
    }, [persones]);

    useEffect(() => {
        const obtenir = async () => {
            setLoading(true);
            const habitacions = await getAdmin("habitacions");
            let calendaris = [];
            await habitacions.resultat.map(async (hab) => {
                if (hab.calendari_booking) {
                    const result_booking = await calendarisBooking(hab?.id);
                    result_booking.map((item) =>
                        calendaris.push({
                            title: hab.nom,
                            start: moment(item.startDate, "YYYYMMDD").format("YYYY-MM-DD"),
                            end: moment(item.endDate, "YYYYMMDD").format("YYYY-MM-DD"),
                        })
                    );
                }
            });
            setTimeout(() => {
                setCalendarisB(calendaris);
                setLoading(false);
            }, 1500);
        };
        booking && obtenir();
    }, [booking]);

    useEffect(() => {
        if (reservaBooking) {
            setDateS(parseISO(reservaBooking.start));
            setDateF(parseISO(reservaBooking.end));
            setHabitacio(habitacions.filter((i) => i.nom === reservaBooking.title)[0]);
        }
    }, [reservaBooking]);

    const onChange = (dates) => {
        const [start, end] = dates;
        const datesOcupades = datesBetweenInclosos(start, end);
        let trobat = [];
        let trobat1 = false;
        let trobat2 = false;
        let data1;
        let data2;
        datesOcupades.forEach((item, index) => {
            intervals.forEach((data) => {
                if (index === 0) {
                    if (item.getTime() === moment(data[0]).subtract(1, "day").toDate().getTime()) {
                        trobat1 = true;
                        data1 = moment(data[0]).subtract(1, "day");
                        data2 = moment(data[1]).subtract(1, "day");
                    }
                }
                if (index === datesOcupades.length + 1) {
                    if (item.getTime() === moment(data[1]).subtract(1, "day").toDate().getTime()) {
                        trobat2 = true;
                        data1 = moment(data[0]).subtract(1, "day");
                        data2 = moment(data[1]).subtract(1, "day");
                    }
                }
            });
        });
        if (trobat1 || trobat2) {
            enqueueSnackbar("Hi ha una reserva entre les dates seleccionades: " + data1.format("YYYY-MM-DD") + " i " + data2.format("YYYY-MM-DD"), {
                variant: "warning",
            });
            trobat = [...trobat, data1];
        }
        datesOcupades.forEach((item) => {
            trobat = [...trobat, ...exclude.filter((d) => d.getTime() === item.getTime())];
        });
        const datesOcupadesNoInclude = datesBetween(start, end);

        const boolDosNits = showNits ? datesOcupadesNoInclude.length === 0 || datesOcupadesNoInclude.length >= 2 : true;

        const preu = preus?.filter((i) => i.persones === persones + 1 && i.habitacio_id === habitacio.id)?.[0]?.preu;

        if (trobat.length === 0 && start?.getTime() !== end?.getTime() && boolDosNits) {
            setDateS(start);
            setDateF(end);
            setPreu(datesOcupadesNoInclude.length === 0 ? preu : Math.round(datesOcupadesNoInclude.length * preu * 100) / 100);
        } else {
            setDateS();
            setDateF();
            setPreu(preu);
        }
    };

    const changeHabitacio = (value) => {
        setHabitacio(value);
        setDateS();
        setDateF();
    };

    const continuar = () => {
        setShowContinuar(!showContinuar);
    };

    const reservaSola = async () => {
        setLoading(true);
        const values = {
            persones_val: persones + 1,
            dateS: moment(dateS).format("YYYY-MM-DD"),
            dateF: moment(dateF).format("YYYY-MM-DD"),
            habitacio_id: habitacio?.id,
            nom_contacte: nomContacte,
            email_contacte: emailContacte,
            phone_contacte: phoneContacte,
            extra: JSON.stringify(selExtras),
            preu: Number(preu) + preuE,
            state_id: state,
            rao: factura?.rao,
            nif: factura?.nif,
            adreca: factura?.adreca,
            poblacio: factura?.poblacio,
            codi_postal: factura?.codi_postal,
            pais: factura?.pais,
            booking: booking ? 1 : 0,
        };
        console.log(values);
        const { message } = await ferReserva(values);

        enqueueSnackbar(message, {
            variant: "success",
        });
        setLoading(false);
        navigate("/admin");
    };

    return (
        <Container maxWidth={false} className={classes.container}>
            <Fade in={loading}>
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            </Fade>
            <LocalizationProvider locale={ca} utils={DateFnsUtils} dateAdapter={DateFnsUtils}>
                <Box>
                    <Title title="Crear reserva" />
                    <Box my={3} />
                    <Box className={classes.block}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) => {
                                        console.log(e);
                                        setBooking(e.target.checked);
                                    }}
                                    name={"Reserva de booking"}
                                />
                            }
                            label={"Reserva de booking?"}
                        />
                    </Box>
                    <Collapse in={booking}>
                        <Box className={classes.block}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Sel·lecciona la reserva de Booking</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={reservaBooking}
                                    label="Sel·lecciona la reserva de Booking"
                                    onChange={(value) => {
                                        setReservaBooking(value.target.value);
                                    }}
                                    MenuProps={{
                                        disableScrollLock: true,
                                    }}
                                >
                                    {calendarisB.map((i) => (
                                        <MenuItem value={i}>
                                            {i.title} ({i.start} - {i.end})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Collapse>
                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <Box className={classes.block}>
                                <Box>
                                    <Collapse in={!booking}>
                                        <Box mb={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Habitació</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={habitacio}
                                                    label="Habitació"
                                                    onChange={(value) => changeHabitacio(value.target.value)}
                                                    MenuProps={{
                                                        disableScrollLock: true,
                                                    }}
                                                >
                                                    {habitacions?.map((hab) => (
                                                        <MenuItem value={hab} selected={habitacio === hab}>
                                                            {hab.nom} - {hab.persones} persones {hab.discapacitats ? <WheelchairPickupRounded /> : ""}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Collapse>

                                    <Box mb={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Persones</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={persones}
                                                label="Persones"
                                                onChange={(value) => {
                                                    console.log(value);
                                                    setPersones(value.target.value);
                                                }}
                                                MenuProps={{
                                                    disableScrollLock: true,
                                                }}
                                            >
                                                {personesRender}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Dates dateF={dateF} dateS={dateS} />
                                    <Box mb={3} mt={3}>
                                        <Grid spacing={3} container>
                                            <Grid item md={4}>
                                                <TextField
                                                    name="nom_contacte"
                                                    label="Nom contacte"
                                                    type="text"
                                                    value={nomContacte}
                                                    onChange={(value) => setNomContacte(value.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item md={4}>
                                                <TextField
                                                    name="nom_contacte"
                                                    label="E-mail contacte"
                                                    type="text"
                                                    value={emailContacte}
                                                    onChange={(value) => setEmailContacte(value.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item md={4}>
                                                <TextField
                                                    name="phone_contacte"
                                                    label="Telèfon contacte"
                                                    type="text"
                                                    value={phoneContacte}
                                                    onChange={(value) => setPhoneContacte(value.target.value)}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Typography variant="caption">Selecciona la data al calendari situat a la dreta</Typography>
                                    <br />
                                    {showNits && <Typography variant="caption">Mínim dues nits</Typography>}
                                    <Box my={3}>
                                        <Typography variant="h3">Extres</Typography>
                                    </Box>
                                    <Grid container spacing={3}>
                                        {selExtras?.map((item) => (
                                            <ExtraSmall item={item} setSelExtras={setSelExtras} />
                                        ))}
                                    </Grid>
                                </Box>
                                <Box my={4} />
                                <Grid container spacing={3}>
                                    <Grid item md={6}>
                                        <Box display="flex" justifyContent="flex-start" alignItems="flex-end">
                                            <Box mr={2}>
                                                <Typography variant="h3">Preu:</Typography>
                                            </Box>

                                            <Typography variant="h2">{preu !== "NaN" ? Number(preu) + preuE : 0} €</Typography>
                                            <Box ml={2}>
                                                <Typography variant="caption">IVA inclòs</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Collapse in={booking}>
                                            <TextField
                                                name="preu"
                                                label="Preu personalitzat"
                                                type="text"
                                                value={preu}
                                                onChange={(value) => setPreu(value.target.value)}
                                                fullWidth
                                            />
                                            <Typography>
                                                <small>Separador de decimals amb un .</small>
                                            </Typography>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Box className={classes.block}>
                                <ReactDatePicker
                                    onChange={onChange}
                                    startDate={dateS}
                                    endDate={dateF}
                                    excludeDates={exclude}
                                    selectsRange
                                    selectsDisabledDaysInRange
                                    locale={ca}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    inline
                                />
                            </Box>
                            <Box className={classes.block}>
                                <Typography variant="h2">Estat</Typography>
                                <Box m={3}></Box>
                                <FormControl fullWidth>
                                    <Select labelId="state_id" id="state_id" value={state} onChange={(value) => setState(value.target.value)}>
                                        {states?.map((item) => {
                                            return <MenuItem value={item.id}>{item.nom}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <FormFactura factura={factura} setFactura={setFactura} />

                <Box className={classes.continuar}>
                    <Collapse in={!booking}>
                        <CustomButton
                            onClick={continuar}
                            title={showContinuar ? "Enrere" : "Fer check-in"}
                            disabled={!dateS || !dateF || !habitacio || persones === ""}
                        />
                    </Collapse>
                    <Box m={2} />
                    <CustomButton
                        onClick={reservaSola}
                        title={"Finalitzar reserva (sense check-in)"}
                        disabled={!dateS || !dateF || !habitacio || persones === ""}
                    />
                </Box>
            </LocalizationProvider>
            <FormulariReserva
                showContinuar={showContinuar}
                habitacio={habitacio}
                dateS={dateS}
                dateF={dateF}
                persones={persones}
                preu={preu + preuE}
                loading={loading}
                setLoading={setLoading}
                adminView={true}
                selExtras={selExtras}
                factura={factura}
                nomContacte={nomContacte}
                emailContacte={emailContacte}
                phoneContacte={phoneContacte}
            />
        </Container>
    );
};

export default ReservaAdd;
