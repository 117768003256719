import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import React from "react";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { Box } from "@mui/system";

const DialogSuccess = ({ open, setOpen, success }) => {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
        >
            <DialogContent>
                <Box textAlign={"center"}>
                    {success === 200 ? (
                        <CheckCircle color="success" sx={{ fontSize: 90 }} />
                    ) : (
                        <Cancel color="danger" sx={{ fontSize: 90 }} />
                    )}
                    {success === 200 ? (
                        <>
                            <Typography variant="h2">
                                Reserva creada amb èxit!
                            </Typography>
                            <Typography variant="body1">
                                Rebràs un correu amb més informació. Recorda fer
                                el check-in i el pagament 48 hores abans del dia
                                d'entrada
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="h2">
                            Hi ha hagut un error al crear la reserva...
                        </Typography>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>D'acord</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogSuccess;
