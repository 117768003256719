import { Box, Container, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '../views/public/Style';

export default function ComComprar() {
    const classes = Styles();
    const { t } = useTranslation();
    return (
        <Container>
            <Grid container>
                <Grid item md={12} sx={12}>
                    <Box mt={2}>
                        <Typography variant="h2">
                            {t('Com comprar un Xec Regal?')}
                        </Typography>
                        <Typography variant="h4" className={classes.text}>
                            1. {t('Escull el teu Xec Regal')}
                        </Typography>
                        <Typography variant="h4" className={classes.text}>
                            2. {t('Fes-ne el pagament')}
                        </Typography>
                        <Typography variant="h4" className={classes.text}>
                            3.{' '}
                            {t(
                                "T'enviarem un correu electrònic amb el Xec que hagis escollit i un codi QR"
                            )}
                        </Typography>
                        <Typography variant="h4" className={classes.text}>
                            4.{' '}
                            {t(
                                'Imprimeix o envia el Xec amb el codi QR a qui vulguis'
                            )}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}
