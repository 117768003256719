import { ArrowRightAlt, Favorite } from "@mui/icons-material";
import { Avatar, Box, Button, Grid, IconButton, Stack, Table, TableBody, TableContainer, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Edit } from "react-feather";
import PersonaEdit from "../../../../components.js/PersonaEdit";
import { getPaisos } from "../../../../database/API";
import CheckinAdmin from "./CheckinAdmin";
import Dada from "./Dada";
import ComprovarPersona from "./ComprovarPersona";
import { checkinAdminLector } from "../../../../database/API";
import { useSnackbar } from "notistack";

function Clients({ reserva, setUp }) {
    const classes = useStyles();
    const [openEdit, setOpenEdit] = useState(false);
    const [openComprovar, setOpenComprovar] = useState(false);
    const [openCheckin, setOpenCheckin] = useState(false);
    const [persona, setPersona] = useState();
    const [paisos, setPaisos] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const obtenir = async () => {
            const { paisos } = await getPaisos();
            const paisosNoms = paisos.map((item) => {
                return { label: item.translations.spa.common };
            });
            setPaisos(paisosNoms.sort((a, b) => (a.label > b.label ? 1 : -1)));
        };
        obtenir();
    }, []);

    const openEditDialog = (row) => {
        setPersona(row);
        setOpenEdit(true);
    };

    const openCheckinDialog = () => {
        setOpenCheckin(true);
    };

    const ferCheckIn = async () => {
        try {
            const message = await checkinAdminLector(reserva.unique_id);
            enqueueSnackbar(message, {
                variant: "success",
            });
            setUp((prev) => prev + 1);
        } catch (error) {
            enqueueSnackbar("Alguna cosa no ha anat bé...", {
                variant: "error",
            });
        }
    };

    return (
        <Box className={classes.block}>
            <Box mb={3} display="flex" justifyContent={"space-between"}>
                <Typography variant="h2">Clients</Typography>
                <Stack spacing={2} direction={"row"}>
                    {reserva?.persones.length === 0 && (
                        <Button onClick={openCheckinDialog} variant="contained">
                            Entrada manual
                        </Button>
                    )}
                    <Button onClick={() => setOpenComprovar(true)} variant="contained">
                        <Favorite />
                        Afegir des de lector
                    </Button>
                    <Button onClick={ferCheckIn} variant="contained">
                        Fer check-in
                    </Button>
                </Stack>
            </Box>
            <Grid container spacing={2}>
                {reserva?.persones.map((row, index) => (
                    <Grid item md={6} key={row.dni}>
                        <Box mb={3} mt={3} display="flex" alignItems="center">
                            <Typography variant="h3">Persona {index + 1}</Typography>
                            <IconButton onClick={() => openEditDialog(row)}>
                                <Edit />
                            </IconButton>
                        </Box>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <Dada title="Nom" value={row.nom + " " + row.cognom1 + " " + (row?.cognom2 ?? "")} />
                                    {row?.dni ? (
                                        <>
                                            <Dada title="DNI" value={row?.dni} />
                                            <Dada title="Nacionalitat" value={row?.nacionalitat} />
                                            <Dada title="Gènere" value={row?.genere} />
                                            <Dada title="Data de naixement" value={moment(row?.naixement).format("DD/MM/YYYY")} />

                                            <Dada
                                                title="DNI Davant"
                                                value={
                                                    <Avatar
                                                        src={"https://api.lacabanaberga.cat/storage/" + row?.dniDavant}
                                                        onClick={() => window.open("https://api.lacabanaberga.cat/storage/" + row?.dniDavant)}
                                                        sx={{ cursor: "pointer" }}
                                                    />
                                                }
                                            />

                                            <Dada
                                                title="DNI Darrere"
                                                value={
                                                    <Avatar
                                                        src={"https://api.lacabanaberga.cat/storage/" + row?.dniDarrere}
                                                        onClick={() => window.open("https://api.lacabanaberga.cat/storage/" + row?.dniDarrere)}
                                                        sx={{ cursor: "pointer" }}
                                                    />
                                                }
                                            />
                                        </>
                                    ) : (
                                        <Dada title="DNI" value="Menor" />
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                ))}
            </Grid>
            <PersonaEdit open={openEdit} setOpen={setOpenEdit} persona={persona} paisos={paisos} setUp={setUp} />
            <CheckinAdmin open={openCheckin} setOpen={setOpenCheckin} reserva={reserva} setUp={setUp} />
            <ComprovarPersona open={openComprovar} setOpen={setOpenComprovar} reserva_id={reserva.id} setUp={setUp} />
        </Box>
    );
}

export default Clients;

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
    },
    dates: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));
